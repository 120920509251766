import { APP_BAR_HEIGHT } from "../navigation/constants";

const commaSeparated = (value, tableMeta, updateValue) => {
    // return new Intl.NumberFormat().format(value)
    return Number(value).toLocaleString('en-IN')
}

const columns = [
    {
        name: "open",
        label: "Open",
        options: {
            setCellProps: () => ({
                align: "center"
            }),
            customBodyRender: commaSeparated
        }
    },
    {
        name: "high",
        label: "High",
        options: {
            setCellProps: () => ({
                align: "center"
            }),
            customBodyRender: commaSeparated
        }
    },
    {
        name: "low",
        label: "Low",
        options: {
            setCellProps: () => ({
                align: "center"
            }),
            customBodyRender: commaSeparated
        }
    },
    {
        name: "close",
        label: "Close",
        options: {
            setCellProps: () => ({
                align: "center"
            }),
            customBodyRender: commaSeparated
        }
    },
    {
        name: "change",
        label: "Change",
        options: {
            setCellProps: () => ({
                align: "center"
            }),
            customBodyRender: (value, tableMeta, updateValue) => {
                return <p style={{color: (value.charAt(0) === '-' ? 'red' : 'green') }}>{value}</p>
            }
        }
    },
    {
        name: "volume",
        label: "Volume",
        options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
                align: "center"
            }),
            customBodyRender: commaSeparated
        }
    },
    {
        name: "delivery",
        label: "Delivery",
        options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
                align: "center"
            }),
            customBodyRender: commaSeparated
        }
    },
    {
        name: "turnover",
        label: "Turnover(Lacs)",
        options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
                align: "center"
            }),
            customBodyRender: commaSeparated
        }
    },
    {
        name: "deliveryPercent",
        label: "Delivery %",
        options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
                align: "center"
            }),
            customBodyRender: commaSeparated
        }
    },
];

export const topDeliveriesColumns = [
    {
        name: "name",
        label: "Name",
        options: {
            filter: true,
            sort: true,
        }
    },
    ...columns
]

export const stockStatsColumns = [
    {
        name: "date",
        label: "Date",
        options: {
            filter: true,
            sort: true,
        }
    },
    ...columns
]

export const INDEX_SELECTOR_HEIGHT = 56;
export const DELIVERY_SEARCH_HEIGHT = 64;

export const options = {
    selectableRows: 'none',
    fixedHeader: true,
    pagination: false,
};