import { configureStore } from "@reduxjs/toolkit";
import { marketStatusSlice } from "./marketStatus";
import { stockListSlice } from "./stocksList";
import { topDeliveryDataSlice } from "./topDeliveryData";

const store = configureStore({
    reducer: {
        marketStatus: marketStatusSlice.reducer,
        stockList: stockListSlice.reducer,
        topDeliveryData: topDeliveryDataSlice.reducer,
    }
})

export default store;