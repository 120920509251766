import * as React from 'react';
import { Box, Stack } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Tab, TabList, Tabs } from '@mui/joy';

const TAB_NAME_MAP = {
  single_day_data_tab: "Single day activity",
  oi_vs_index_tab: "Participant OI vs Index graph"
}

const FiiDii = () => {
  const [tabNames, setTabName] = React.useState(TAB_NAME_MAP);
  const location = useLocation();
  const handleTabName = (tabIdx, tabName) => {
    setTabName({
      ...tabNames,
      [tabIdx]: tabName
    });
  }

  return (
    <Stack sx={{ flexGrow: 1, overflow: 'auto', display: 'flex' }}>
      <Tabs
        sx={{ width: '100%' }}
        value={location.pathname}
        defaultValue="/fiidii/singleDayData">
        <TabList sticky='top'>
          <Tab component={Link} to="/fiidii/singleDayData" value="/fiidii/singleDayData">
            {tabNames.single_day_data_tab}
          </Tab>
          <Tab component={Link} to="/fiidii/futureNetOIvsIndex" value="/fiidii/futureNetOIvsIndex">
            {tabNames.oi_vs_index_tab}
          </Tab>
        </TabList>
      </Tabs>
      <Box sx={{ flexGrow: 1, overflow: 'auto', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <Outlet context={handleTabName} />
      </Box>
    </Stack>
  );
}

export default FiiDii;