import { ChevronLeft, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Avatar, Box, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { signOut } from 'firebase/auth';
import React from 'react'
import { auth } from '../../config/firebase';

const UserMenu = ({ currentUser }) => {
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logout = async () => {
        await signOut(auth);
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Menu">
                <Stack direction="row" alignItems="center" onClick={handleOpenUserMenu}>
                    <IconButton sx={{ p: 0 }}>
                        <Avatar
                            src={currentUser?.photoURL}
                            alt={currentUser?.displayName} />
                    </IconButton>
                    <Typography fontWeight={1} marginLeft={1}>
                        {currentUser?.displayName}
                    </Typography>
                    {anchorElUser === null ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                </Stack>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem onClick={handleCloseUserMenu}>
                    <Typography textAlign="center" onClick={logout}>Logout</Typography>
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default UserMenu