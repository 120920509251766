export const formatTime = (timeString) => {
    const [hourString, minute, seconds] = timeString.split("-");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + ":" + seconds + (hour < 12 ? " AM" : " PM");
}

export const dateToYYMMdd = (date) => {
    const yyyy = date.getFullYear() + '';
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return yyyy.substring(2) + mm + dd;
}