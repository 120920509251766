import { Box, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { symbolToNames } from '../../constants/symbolToNames';
import { Chip, ChipDelete } from '@mui/joy';
import { AccessTime } from '@mui/icons-material';
import { getDateString, yymmddToDashedDate } from '../../utils/DateUtil';
import { topDeliveryDataActions } from '../../store/topDeliveryData';
import ToggleButtonSelector from '../fiidii/ToggleButtonSelector';
import { columns, DELIVERY_SEARCH_HEIGHT, INDEX_SELECTOR_HEIGHT, options, topDeliveriesColumns } from './constants';
import { APP_BAR_HEIGHT } from '../navigation/constants';

const INITIAL_STATE = {
    syncedDate: {
        value: ''
    },
    allStockTop: [],
    nifty500Bottom: [],
    allStockBottom: [],
    nifty500Top: []
}

const preparePayload = (stats) => {
    let statsArray = [];
    Object.entries(stats).forEach(([key, value]) => {
        const netChange = (value.close - value.prevClose).toFixed(2);
        const changePct = (netChange * 100 / value.prevClose).toFixed(1);
        const changeTxt = netChange + '(' + changePct + '%)';
        statsArray.push({
            symbol: key,
            name: symbolToNames[key] + ' (' + key + ')',
            deliveryPercent: (value.delivery * 100 / value.volume).toFixed(1),
            change: changeTxt,
            ...value
        })
    });
    return statsArray;
}

const deliveryStatsReducer = (state, action) => {
    switch (action.type) {
        case "DATA_CLEANUP":
            return INITIAL_STATE;
        case "DATA_LOADED":
            const newState = JSON.parse(JSON.stringify(INITIAL_STATE));
            const data = action.payload;
            newState.nifty500Top = preparePayload(data.nifty500Top);
            newState.nifty500Bottom = preparePayload(data.nifty500Bottom);
            newState.allStockTop = preparePayload(data.allStockTop);
            newState.allStockBottom = preparePayload(data.allStockBottom);
            return newState;
        default:
            return state;
    }
}

const tableOptions = {
    ...options,
    tableBodyMaxHeight: `calc(100vh - ${APP_BAR_HEIGHT}px - ${DELIVERY_SEARCH_HEIGHT}px - ${INDEX_SELECTOR_HEIGHT}px - 64px)`,
    sortOrder: {
        name: 'deliveryPercent',
        direction: 'desc'
    }
}

const TopStats = () => {
    const [selectedIndex, setSelectedIndex] = useState("nifty500");
    const [deliveryStats, dispatch] = useReducer(deliveryStatsReducer, INITIAL_STATE);
    const [selectedMetric, setSelectedMetric] = useState("highest");
    const [syncedDate, setSyncedDate] = useState('');
    const [loading, setLoading] = useState(true);
    const [errorWhileLoading, setErrorWhileLoading] = useState(false);

    useEffect(() => {
        getTopStats();
    }, []);

    const setIndex = (event) => {
        setSelectedIndex(event.target.value);
    }

    const getTopStats = async () => {
        setLoading(true);
        setErrorWhileLoading(false);
        try {
            const res = await axios.get(process.env.REACT_APP_SERVICE_BASE_URL + "/deliveryStats");
            dispatch({ type: "DATA_LOADED", payload: res.data.data });
            setSyncedDate(getDateString(yymmddToDashedDate(res.data.data.syncedDate.value)));
        } catch (err) {
            setErrorWhileLoading(true);
        }
        setLoading(false);
    };

    return (
        <Stack direction='column'>
            <Stack direction='row' justifyContent='space-between' padding={1} boxSizing='border-box' height={INDEX_SELECTOR_HEIGHT}>
                <Box>
                    <FormControl size="small">
                        <InputLabel>Index</InputLabel>
                        <Select
                            value={selectedIndex}
                            label="Index"
                            onChange={setIndex}
                        >
                            <MenuItem value="nifty500">Nifty 500</MenuItem>
                            <MenuItem value="allStocks">All NSE stocks</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <ToggleButtonSelector
                    setSelectedValue={setSelectedMetric}
                    Color="secondary"
                    DefaultSelectedTab={selectedMetric}
                    TabsData={[
                        { value: "highest", label: "Highest" },
                        { value: "lowest", label: "Lowest" }
                    ]}
                />
            </Stack>
            <MUIDataTable
                title={(selectedMetric === 'highest' ? "Highest" : "Lowest") + " delivery stocks " + "(" + syncedDate + ")"}
                data={selectedIndex === 'nifty500' ?
                    selectedMetric === 'highest' ? deliveryStats.nifty500Top : deliveryStats.nifty500Bottom :
                    selectedMetric === 'highest' ? deliveryStats.allStockTop : deliveryStats.allStockBottom}
                columns={topDeliveriesColumns}
                options={tableOptions}
            />
        </Stack>
    )
}

export default TopStats