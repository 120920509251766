import { TrendingDown, TrendingUp } from '@mui/icons-material'
import { Chip } from '@mui/joy'
import { TableCell, Typography } from '@mui/material'
import React from 'react'

const SubRow = ({ segment, netChange }) => {
    return (
        <>
            <TableCell align="right" sx={{ padding: 1 }}>
                <Typography fontWeight={550} fontSize='1.2rem'>
                    {segment}
                </Typography>
            </TableCell>
            <TableCell align="right" sx={{ padding: 1 }}>
                <Typography fontWeight={600} fontSize='1.2rem'>
                    {(segment === 'Cash' ? 'Rs. ' : '') +
                        Number(netChange).toLocaleString('en-IN') +
                        (segment === 'Cash' ? ' Cr.' : '')
                    }
                </Typography>
            </TableCell>
            <TableCell align="right" sx={{ padding: 1 }}>
                {netChange >= 0 ?
                    <Chip size='lg' variant='solid' color='success' startDecorator={<TrendingUp />}>
                        Bullish
                    </Chip> :
                    <Chip size='lg' variant='solid' color='danger' startDecorator={<TrendingDown />}>
                        Bearish
                    </Chip>
                }
            </TableCell>
        </>
    )
}

export default SubRow