import React, { useEffect, useReducer, useState } from 'react'
import { database } from "../../config/firebase";
import {
    limitToLast,
    onChildAdded,
    onValue,
    orderByKey,
    query,
    ref,
} from "firebase/database";
import {
    CartesianGrid,
    Label,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import ToggleButtonSelector from '../fiidii/ToggleButtonSelector';
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { dateToYYMMdd, formatTime } from '../../util/DateUtil';
import { Chip } from '@mui/joy';
import { AccessTime } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import MarketStatus from '../marketStatus';

const chainReducer = (state, action) => {
    switch (action.type) {
        case "PREPEND_OLD_MESSAGES":
            return action.payload;
        case "APPEND_NEW_MESSAGE":
            if (state.length !== 0 && state.at(-1).date === action.payload.date) {
                return state;
            }
            state.push(action.payload);
            return state;
        case "CLEAR_OLD_MESSAGES":
        default:
            return [];
    }
};

const StrengthIndicator = () => {
    const marketStatus = useSelector(state => state.marketStatus.data);
    const [strengthData, dispatch] = useReducer(chainReducer, []);
    const [strikeSelect, setStrikeSelect] = useState([]);
    const [strikeLineName, setStrikeLineName] = useState("Net ATM Strength");
    const [indexLineName, setIndexLineName] = useState("Nifty");
    const [selectedStrike, setSelectedStrike] = useState("netStrength");
    const [instrument, setInstrument] = useState("NIFTY");
    const [latestSyncedDate, setLatestSyncedDate] = useState("240503");
    const [randomKey, setRandomKey] = useState(0);

    let singleMessageQuery = query(
        ref(database, "option-chain-strength/" + latestSyncedDate + "/" + instrument),
        limitToLast(1)
    );

    let oldMessageQuery = query(
        ref(database, "option-chain-strength/" + latestSyncedDate + "/" + instrument),
        orderByKey(),
    );

    const preparePayload = (key, data) => {
        let strength = {}
        Object.keys(data.strength).forEach((strike) => {
            if (strike % 10000 !== 0.0) {
                return;
            }
            strength[strike / 100] = Math.trunc(data.strength[strike] / 1000);
        })
        setStrikeSelect(Object.keys(strength));
        return {
            date: formatTime(key),
            netStrength: Math.trunc(data.netStrength / 1000),
            price: data.price,
            ...strength
        }
    }

    const fetchPreviousStrengthMessages = () => {
        onValue(
            oldMessageQuery,
            (data) => {
                let flattened = [];
                Object.entries(data.val()).map(([key, value]) => {
                    flattened.push(preparePayload(key, value))
                })
                dispatch({ type: "PREPEND_OLD_MESSAGES", payload: flattened });
            }, { onlyOnce: true, }
        );
    };

    useEffect(() => {
        setSelectedStrike("netStrength");
        setIndexLineName(instrument);
        fetchPreviousStrengthMessages();
        const unsubscribe = onChildAdded(singleMessageQuery, (data) => {
            dispatch({
                type: "APPEND_NEW_MESSAGE",
                payload: preparePayload(data.key, data.val())
            });
            setRandomKey(Math.random());
        });

        return () => {
            unsubscribe();
            dispatch({ type: "CLEAR_OLD_MESSAGES" });
        };
    }, [instrument, latestSyncedDate]);

    useEffect(() => {
        if (marketStatus.dateTime === undefined) {
            return;
        }
        setLatestSyncedDate(dateToYYMMdd(new Date(marketStatus.dateTime)))
    }, [marketStatus])

    useEffect(() => {
        selectedStrike === "netStrength" ?
            setStrikeLineName("Net ATM strength") :
            setStrikeLineName("OI Strength at " + selectedStrike)
    }, [selectedStrike])

    const setStrike = (event) => {
        setSelectedStrike(event.target.value);
    }

    return (
        <>
            <Stack direction="row" margin={2}>
                <Stack direction="row" gap={3} flexGrow={1} paddingLeft={7}>
                    <FormControl size="small">
                        <InputLabel>Strike</InputLabel>
                        <Select
                            value={selectedStrike}
                            label="Strike"
                            onChange={setStrike}
                        >
                            <MenuItem value="netStrength">Net ATM Strength</MenuItem>
                            {
                                strikeSelect.map((strike) =>
                                    <MenuItem value={strike}>
                                        {strike}
                                    </MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    <ToggleButtonSelector
                        setSelectedValue={setInstrument}
                        Color="secondary"
                        DefaultSelectedTab={instrument}
                        TabsData={[
                            { value: "NIFTY", label: "NIFTY" },
                            { value: "BANKNIFTY", label: "BANKNIFTY" },
                            { value: "FINNIFTY", label: "FINNIFTY" },
                            { value: "MIDCPNIFTY", label: "MIDCPNIFTY" },
                        ]}
                    />
                </Stack>
                <MarketStatus />
            </Stack>
            <ResponsiveContainer width='99%' height='80%' style={{ margin: 5 }}>
                <LineChart
                    data={strengthData}
                    key={randomKey}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis yAxisId="left" stroke="blue" strokeWidth="2"
                        domain={['auto']}
                    >
                        <Label angle={-90} position={"insideLeft"}
                            style={{ fontWeight: '900', fontSize: '1rem' }}>
                            {strikeLineName}
                        </Label>
                    </YAxis>
                    <YAxis
                        yAxisId="right"
                        orientation="right"
                        stroke="red"
                        strokeWidth="2"
                        domain={['auto']}
                    >
                        <Label angle={90} position={"insideRight"}
                            style={{ fontWeight: '900', fontSize: '1rem' }}>
                            {indexLineName}
                        </Label>
                    </YAxis>
                    <Tooltip />
                    <Legend verticalAlign='top' />
                    <Line
                        yAxisId="left"
                        type="monotone"
                        dataKey={selectedStrike}
                        stroke="blue"
                        strokeWidth="2"
                        dot={false}
                        animationEasing='ease-in-out'
                        name={strikeLineName}
                    />
                    <Line
                        yAxisId="right"
                        type="monotone"
                        dataKey={"price"}
                        stroke="red"
                        strokeWidth="2"
                        dot={false}
                        animationEasing='ease-in-out'
                        name={indexLineName}
                    />
                    {
                        strengthData.length > 1 &&
                        <ReferenceLine y={strengthData.at(-1)[selectedStrike]} yAxisId="left" stroke="blue" strokeDasharray="3 3" />
                    }
                </LineChart>
            </ResponsiveContainer>
        </>
    )
}

export default StrengthIndicator