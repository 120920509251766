import { signInWithPopup } from "firebase/auth";
import GoogleButton from "react-google-button";
import { auth, googleAuthProvider } from "../../config/firebase";

const LoginOptions = () => {
    
    const handleGoogleLogin = async () => {
        try {
            await signInWithPopup(auth, googleAuthProvider);
        } catch (err) {
            console.log('Failed to login');
        }
    };

    return <GoogleButton onClick={handleGoogleLogin} type='dark' label='Login with Google' style={{ width: '100%', fontWeight: 'bolder' }} />;
}

export default LoginOptions;