export const yymmddToDashedDate = (yymmdd) => {
    var year = '20' + yymmdd.substring(0, 2);
    var month = yymmdd.substring(2, 4);
    var day = yymmdd.substring(4, 6);

    return new Date(year, month - 1, day);
}

export const getDateString = (date) => {
    return date.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'long', year: 'numeric'
    }).replace(/ /g, '-');
}