import { createSlice } from "@reduxjs/toolkit";
import { getDateString, yymmddToDashedDate } from "../utils/DateUtil";

const INITIAL_STATE = {
    data: {
        syncedDate: ''
    }
}

export const topDeliveryDataSlice = createSlice({
    name: 'topDeliveryData',
    initialState: INITIAL_STATE,
    reducers: {
        update(state, action) {
            state.data = {
                syncedDate: getDateString(yymmddToDashedDate(action.payload))
            }
        }
    }
})

export const topDeliveryDataActions = topDeliveryDataSlice.actions;