import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import GoogleButton from 'react-google-button';
import { AuthContext } from '../../context/AuthContextProvider';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleAuthProvider } from '../../config/firebase';
import UserMenu from './UserMenu';
import { APP_BAR_HEIGHT, DRAWER_WIDTH } from './constants';

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    height: APP_BAR_HEIGHT,
    backgroundColor: '#1B1A55',
    ...(open && {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const AppTopBar = ({ open, handleDrawerOpen }) => {
    const { currentUser } = React.useContext(AuthContext);
    const handleGoogleLogin = async () => {
        try {
            await signInWithPopup(auth, googleAuthProvider);
        } catch (err) {
            console.log('Failed to login');
        }
    };

    return (
        <AppBar position="fixed" open={open}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div" flexGrow={1}>
                    MarketDirection.in
                </Typography>
                {
                    !currentUser && <GoogleButton onClick={handleGoogleLogin} type='light' label='Login' style={{ width: '8rem', fontWeight: 'bolder' }} />
                }
                {
                    currentUser && <UserMenu currentUser={currentUser} />
                }
            </Toolbar>
        </AppBar>
    )
}

export default AppTopBar