import { CircularProgress } from '@mui/joy';
import { Box, Button, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import axios from 'axios';
import MUIDataTable from 'mui-datatables'
import React, { useEffect, useReducer, useState } from 'react'
import { symbolToNames } from '../../constants/symbolToNames';

const resultsReducer = (state, action) => {
    switch (action.type) {
        case "DATA_CLEANUP":
            return INITIAL_STATE;
        case "DATA_LOADED":
            const newState = JSON.parse(JSON.stringify(INITIAL_STATE));
            const fnoCalendar = action.payload.fno;
            const today = new Date();
            today.setDate(today.getDate() - 1);
            Object.entries(fnoCalendar).forEach(([k, v]) => {
                const res = {
                    name: symbolToNames[k],
                    symbol: k,
                    date: v
                };
                const d = new Date(v);
                today - d < 0 ? newState.fno.upcoming.push(res) : newState.fno.past.push(res);
            })
            const nonfnoCalendar = action.payload.nonFno;
            Object.entries(nonfnoCalendar).forEach(([k, v]) => {
                const res = {
                    name: symbolToNames[k],
                    symbol: k,
                    date: v
                };
                const d = new Date(v);
                today - d < 0 ? newState.nonfno.upcoming.push(res) : newState.nonfno.past.push(res);
            })
            newState.fno.upcoming.sort((a, b) => new Date(a.date) - new Date(b.date));
            newState.fno.past.sort((a, b) => new Date(b.date) - new Date(a.date));
            newState.nonfno.upcoming.sort((a, b) => new Date(a.date) - new Date(b.date));
            newState.nonfno.past.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newState;
        default:
            return state;
    }
}

const INITIAL_STATE = {
    fno: {
        upcoming: [],
        past: []
    },
    nonfno: {
        upcoming: [],
        past: []
    }
}

const ResultCalendar = () => {
    const [loading, setLoading] = useState(true);
    const [errorWhileLoading, setErrorWhileLoading] = useState(false);
    const [results, dispatch] = useReducer(resultsReducer, INITIAL_STATE);
    const [segmentSelected, setSegmentSelected] = useState('fno');

    const getResultsCalendar = async () => {
        setLoading(true);
        setErrorWhileLoading(false);
        try {
            const res = await axios.get(process.env.REACT_APP_SERVICE_BASE_URL + "/resultsCalendar");
            dispatch({ type: "DATA_LOADED", payload: res.data.data });
        } catch (err) {
            setErrorWhileLoading(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        getResultsCalendar();
        return () => {
            dispatch({ type: "DATA_CLEANUP" });
        }
    }, []);

    const columns = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "symbol",
            label: "Symbol",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: false,
            }
        }
    ];

    const options = {
        selectableRows: 'none'
    };

    if (loading) {
        return <Stack alignSelf="center" justifyContent="center" flexGrow={1}>
            <CircularProgress />
        </Stack>
    }

    if (errorWhileLoading) {
        return <Stack direction='column' alignSelf="center" justifyContent="center" flexGrow={1}>
            <Typography>
                Something went wrong
            </Typography>
            <Button onClick={getResultsCalendar}>
                Try again
            </Button>
        </Stack>
    }

    const handleSegmentChange = (event, segment) => {
        setSegmentSelected(segment);
    }

    return (
        <Stack direction="column">
            <Box margin={2}>
                <ToggleButtonGroup
                    value={segmentSelected}
                    exclusive
                    onChange={handleSegmentChange}
                    aria-label="text alignment"
                >
                    <ToggleButton value="fno">
                        <Typography>
                            F&O
                        </Typography>
                    </ToggleButton>
                    <ToggleButton value="all">
                        <Typography>
                            All stocks
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Stack direction="row" sx={{ justifyContent: 'space-around', flexGrow: 1 }} width="100%" gap={0.5}>
                {results.fno.upcoming.length > 0 &&
                    <Box sx={{ flexGrow: 1 }}>
                        <MUIDataTable
                            title={"Upcoming results"}
                            data={segmentSelected === 'fno' ? results.fno.upcoming : results.nonfno.upcoming}
                            columns={columns}
                            options={options}
                        />
                    </Box>
                }
                {results.fno.past.length > 0 &&
                    <Box sx={{ flexGrow: 1 }}>
                        <MUIDataTable
                            title={"Past results"}
                            data={segmentSelected === 'fno' ? results.fno.past : results.nonfno.past}
                            columns={columns}
                            options={options}
                        />
                    </Box>
                }
            </Stack>
        </Stack>
    )
}

export default ResultCalendar