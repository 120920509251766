import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react'
import { getDateString, yymmddToDashedDate } from '../../utils/DateUtil';
import { columns, DELIVERY_SEARCH_HEIGHT, INDEX_SELECTOR_HEIGHT, options, stockStatsColumns } from './constants';
import { symbolToNames } from '../../constants/symbolToNames';
import MUIDataTable from 'mui-datatables';
import { APP_BAR_HEIGHT } from '../navigation/constants';

const preparePayload = (stats) => {
    let statsArray = [];
    Object.entries(stats).forEach(([key, value]) => {
        const netChange = (value.close - value.prevClose).toFixed(2);
        const changePct = (netChange * 100 / value.prevClose).toFixed(1);
        const changeTxt = netChange + '(' + changePct + '%)';
        statsArray.push({
            date: getDateString(yymmddToDashedDate(key)),
            deliveryPercent: (value.delivery * 100 / value.volume).toFixed(1),
            change: changeTxt,
            ...value
        })
    });
    return statsArray.reverse();
}

const INITIAL_STATE = {
    stockPriceVolumeHistory: []
}

const stockStatsReducer = (state, action) => {
    switch (action.type) {
        case "DATA_CLEANUP":
            return INITIAL_STATE;
        case "DATA_LOADED":
            const newState = JSON.parse(JSON.stringify(INITIAL_STATE));
            const data = action.payload;
            newState.stockPriceVolumeHistory = preparePayload(data.stockPriceVolumeHistory);

            return newState;
        default:
            return state;
    }
}


const tableOptions = {
    ...options,
    tableBodyMaxHeight: `calc(100vh - ${APP_BAR_HEIGHT}px - ${DELIVERY_SEARCH_HEIGHT}px - 64px)`,
}

const SingleStockHistory = ({ stock }) => {

    const [loading, setLoading] = useState(true);
    const [errorWhileLoading, setErrorWhileLoading] = useState(false);
    const [stockStats, dispatch] = useReducer(stockStatsReducer, INITIAL_STATE);

    const getStockDeliveryStats = async (stock) => {
        setLoading(true);
        setErrorWhileLoading(false);
        try {
            const res = await axios.get(process.env.REACT_APP_SERVICE_BASE_URL + "/stockPriceVolume/" + stock);
            dispatch({ type: "DATA_LOADED", payload: res.data.data });
        } catch (err) {
            setErrorWhileLoading(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        getStockDeliveryStats(stock);
    }, [stock])

    return (
        <MUIDataTable
            title={symbolToNames[stock]}
            data={stockStats.stockPriceVolumeHistory}
            columns={stockStatsColumns}
            options={tableOptions}
        />
    )
}

export default SingleStockHistory