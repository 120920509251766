export const symbolToNames = {
    "RELIANCE": "Reliance Industries Limited",
    "TCS": "Tata Consultancy Services Limited",
    "HDFCBANK": "HDFC Bank Limited",
    "ICICIBANK": "ICICI Bank Limited",
    "BHARTIARTL": "Bharti Airtel Limited",
    "SBIN": "State Bank of India",
    "INFY": "Infosys Limited",
    "LICI": "Life Insurance Corporation Of India",
    "ITC": "ITC Limited",
    "HINDUNILVR": "Hindustan Unilever Limited",
    "LT": "Larsen & Toubro Limited",
    "BAJFINANCE": "Bajaj Finance Limited",
    "HCLTECH": "HCL Technologies Limited",
    "MARUTI": "Maruti Suzuki India Limited",
    "SUNPHARMA": "Sun Pharmaceutical Industries Limited",
    "ADANIENT": "Adani Enterprises Limited",
    "KOTAKBANK": "Kotak Mahindra Bank Limited",
    "TITAN": "Titan Company Limited",
    "ONGC": "Oil & Natural Gas Corporation Limited",
    "TATAMOTORS": "Tata Motors Limited",
    "NTPC": "NTPC Limited",
    "AXISBANK": "Axis Bank Limited",
    "DMART": "Avenue Supermarts Limited",
    "ADANIGREEN": "Adani Green Energy Limited",
    "ADANIPORTS": "Adani Ports and Special Economic Zone Limited",
    "ULTRACEMCO": "UltraTech Cement Limited",
    "ASIANPAINT": "Asian Paints Limited",
    "COALINDIA": "Coal India Limited",
    "BAJAJFINSV": "Bajaj Finserv Limited",
    "BAJAJ-AUTO": "Bajaj Auto Limited",
    "POWERGRID": "Power Grid Corporation of India Limited",
    "NESTLEIND": "Nestle India Limited",
    "WIPRO": "Wipro Limited",
    "M&M": "Mahindra & Mahindra Limited",
    "IOC": "Indian Oil Corporation Limited",
    "JIOFIN": "Jio Financial Services Limited",
    "HAL": "Hindustan Aeronautics Limited",
    "DLF": "DLF Limited",
    "ADANIPOWER": "Adani Power Limited",
    "JSWSTEEL": "JSW Steel Limited",
    "TATASTEEL": "Tata Steel Limited",
    "SIEMENS": "Siemens Limited",
    "IRFC": "Indian Railway Finance Corporation Limited",
    "VBL": "Varun Beverages Limited",
    "ZOMATO": "Zomato Limited",
    "PIDILITIND": "Pidilite Industries Limited",
    "GRASIM": "Grasim Industries Limited",
    "SBILIFE": "SBI Life Insurance Company Limited",
    "BEL": "Bharat Electronics Limited",
    "LTIM": "LTIMindtree Limited",
    "TRENT": "Trent Limited",
    "PNB": "Punjab National Bank",
    "INDIGO": "InterGlobe Aviation Limited",
    "BANKBARODA": "Bank of Baroda",
    "HDFCLIFE": "HDFC Life Insurance Company Limited",
    "ABB": "ABB India Limited",
    "BPCL": "Bharat Petroleum Corporation Limited",
    "PFC": "Power Finance Corporation Limited",
    "GODREJCP": "Godrej Consumer Products Limited",
    "TATAPOWER": "Tata Power Company Limited",
    "HINDALCO": "Hindalco Industries Limited",
    "HINDZINC": "Hindustan Zinc Limited",
    "TECHM": "Tech Mahindra Limited",
    "AMBUJACEM": "Ambuja Cements Limited",
    "INDUSINDBK": "IndusInd Bank Limited",
    "CIPLA": "Cipla Limited",
    "GAIL": "GAIL (India) Limited",
    "RECLTD": "REC Limited",
    "BRITANNIA": "Britannia Industries Limited",
    "UNIONBANK": "Union Bank of India",
    "ADANIENSOL": "Adani Energy Solutions Limited",
    "IOB": "Indian Overseas Bank",
    "LODHA": "Macrotech Developers Limited",
    "EICHERMOT": "Eicher Motors Limited",
    "CANBK": "Canara Bank",
    "TATACONSUM": "TATA CONSUMER PRODUCTS LIMITED",
    "DRREDDY": "Dr. Reddy's Laboratories Limited",
    "TVSMOTOR": "TVS Motor Company Limited",
    "ZYDUSLIFE": "Zydus Lifesciences Limited",
    "ATGL": "Adani Total Gas Limited",
    "VEDL": "Vedanta Limited",
    "CHOLAFIN": "Cholamandalam Investment and Finance Company Limited",
    "HAVELLS": "Havells India Limited",
    "HEROMOTOCO": "Hero MotoCorp Limited",
    "DABUR": "Dabur India Limited",
    "SHREECEM": "SHREE CEMENT LIMITED",
    "MANKIND": "Mankind Pharma Limited",
    "BAJAJHLDNG": "Bajaj Holdings & Investment Limited",
    "DIVISLAB": "Divi's Laboratories Limited",
    "APOLLOHOSP": "Apollo Hospitals Enterprise Limited",
    "NHPC": "NHPC Limited",
    "SHRIRAMFIN": "Shriram Finance Limited",
    "BOSCHLTD": "Bosch Limited",
    "TORNTPHARM": "Torrent Pharmaceuticals Limited",
    "ICICIPRULI": "ICICI Prudential Life Insurance Company Limited",
    "IDBI": "IDBI Bank Limited",
    "JSWENERGY": "JSW Energy Limited",
    "JINDALSTEL": "Jindal Steel & Power Limited",
    "BHEL": "Bharat Heavy Electricals Limited",
    "INDHOTEL": "The Indian Hotels Company Limited",
    "CUMMINSIND": "Cummins India Limited",
    "ICICIGI": "ICICI Lombard General Insurance Company Limited",
    "CGPOWER": "CG Power and Industrial Solutions Limited",
    "MCDOWELL-N": "United Spirits Limited",
    "HDFCAMC": "HDFC Asset Management Company Limited",
    "MAXHEALTH": "Max Healthcare Institute Limited",
    "SOLARINDS": "Solar Industries India Limited",
    "MOTHERSON": "Samvardhana Motherson International Limited",
    "INDUSTOWER": "Indus Towers Limited",
    "POLYCAB": "Polycab India Limited",
    "OFSS": "Oracle Financial Services Software Limited",
    "SRF": "SRF Limited",
    "IRCTC": "Indian Railway Catering And Tourism Corporation Limited",
    "COLPAL": "Colgate Palmolive (India) Limited",
    "LUPIN": "Lupin Limited",
    "NAUKRI": "Info Edge (India) Limited",
    "TIINDIA": "Tube Investments of India Limited",
    "INDIANB": "Indian Bank",
    "HINDPETRO": "Hindustan Petroleum Corporation Limited",
    "BERGEPAINT": "Berger Paints (I) Limited",
    "YESBANK": "Yes Bank Limited",
    "TORNTPOWER": "Torrent Power Limited",
    "OIL": "Oil India Limited",
    "SBICARD": "SBI Cards and Payment Services Limited",
    "IDEA": "Vodafone Idea Limited",
    "MARICO": "Marico Limited",
    "GODREJPROP": "Godrej Properties Limited",
    "AUROPHARMA": "Aurobindo Pharma Limited",
    "UCOBANK": "UCO Bank",
    "BANKINDIA": "Bank of India",
    "PERSISTENT": "Persistent Systems Limited",
    "MUTHOOTFIN": "Muthoot Finance Limited",
    "NMDC": "NMDC Limited",
    "ALKEM": "Alkem Laboratories Limited",
    "PIIND": "PI Industries Limited",
    "LTTS": "L&T Technology Services Limited",
    "GICRE": "General Insurance Corporation of India",
    "TATACOMM": "Tata Communications Limited",
    "JSL": "Jindal Stainless Limited",
    "MRF": "MRF Limited",
    "SAIL": "Steel Authority of India Limited",
    "PGHH": "Procter & Gamble Hygiene and Health Care Limited",
    "SUZLON": "Suzlon Energy Limited",
    "LINDEINDIA": "Linde India Limited",
    "SUPREMEIND": "Supreme Industries Limited",
    "CONCOR": "Container Corporation of India Limited",
    "OBEROIRLTY": "Oberoi Realty Limited",
    "ASTRAL": "Astral Limited",
    "IDFCFIRSTB": "IDFC First Bank Limited",
    "RVNL": "Rail Vikas Nigam Limited",
    "BHARATFORG": "Bharat Forge Limited",
    "CENTRALBK": "Central Bank of India",
    "JSWINFRA": "JSW Infrastructure Limited",
    "POLICYBZR": "PB Fintech Limited",
    "ASHOKLEY": "Ashok Leyland Limited",
    "THERMAX": "Thermax Limited",
    "PHOENIXLTD": "The Phoenix Mills Limited",
    "GMRINFRA": "GMR Airports Infrastructure Limited",
    "TATAELXSI": "Tata Elxsi Limited",
    "PATANJALI": "Patanjali Foods Limited",
    "SJVN": "SJVN Limited",
    "PRESTIGE": "Prestige Estates Projects Limited",
    "ACC": "ACC Limited",
    "NYKAA": "FSN E-Commerce Ventures Limited",
    "SUNDARMFIN": "Sundaram Finance Limited",
    "UBL": "United Breweries Limited",
    "ABCAPITAL": "Aditya Birla Capital Limited",
    "MPHASIS": "MphasiS Limited",
    "BALKRISIND": "Balkrishna Industries Limited",
    "DIXON": "Dixon Technologies (India) Limited",
    "MAHABANK": "Bank of Maharashtra",
    "KALYANKJIL": "Kalyan Jewellers India Limited",
    "SCHAEFFLER": "Schaeffler India Limited",
    "AWL": "Adani Wilmar Limited",
    "APLAPOLLO": "APL Apollo Tubes Limited",
    "TATATECH": "Tata Technologies Limited",
    "SONACOMS": "Sona BLW Precision Forgings Limited",
    "KPITTECH": "KPIT Technologies Limited",
    "FACT": "Fertilizers and Chemicals Travancore Limited",
    "PSB": "Punjab & Sind Bank",
    "PETRONET": "Petronet LNG Limited",
    "L&TFH": "L&T Finance Holdings Limited",
    "UNOMINDA": "UNO Minda Limited",
    "PAGEIND": "Page Industries Limited",
    "MRPL": "Mangalore Refinery and Petrochemicals Limited",
    "AUBANK": "AU Small Finance Bank Limited",
    "MAZDOCK": "Mazagon Dock Shipbuilders Limited",
    "HUDCO": "Housing & Urban Development Corporation Limited",
    "GUJGASLTD": "Gujarat Gas Limited",
    "NIACL": "The New India Assurance Company Limited",
    "CRISIL": "CRISIL Limited",
    "AIAENG": "AIA Engineering Limited",
    "FEDERALBNK": "The Federal Bank  Limited",
    "IREDA": "Indian Renewable Energy Development Agency Limited",
    "VOLTAS": "Voltas Limited",
    "DALBHARAT": "Dalmia Bharat Limited",
    "POONAWALLA": "Poonawalla Fincorp Limited",
    "MEDANTA": "Global Health Limited",
    "IRB": "IRB Infrastructure Developers Limited",
    "3MINDIA": "3M India Limited",
    "MFSL": "Max Financial Services Limited",
    "M&MFIN": "Mahindra & Mahindra Financial Services Limited",
    "UPL": "UPL Limited",
    "HONAUT": "Honeywell Automation India Limited",
    "BSE": "BSE Limited",
    "FLUOROCHEM": "Gujarat Fluorochemicals Limited",
    "COFORGE": "Coforge Limited",
    "LICHSGFIN": "LIC Housing Finance Limited",
    "GLAXO": "GlaxoSmithKline Pharmaceuticals Limited",
    "DELHIVERY": "Delhivery Limited",
    "BDL": "Bharat Dynamics Limited",
    "STARHEALTH": "Star Health and Allied Insurance Company Limited",
    "FORTIS": "Fortis Healthcare Limited",
    "BIOCON": "Biocon Limited",
    "COROMANDEL": "Coromandel International Limited",
    "NLCINDIA": "NLC India Limited",
    "TATAINVEST": "Tata Investment Corporation Limited",
    "JKCEMENT": "JK Cement Limited",
    "IPCALAB": "IPCA Laboratories Limited",
    "METROBRAND": "Metro Brands Limited",
    "KEI": "KEI Industries Limited",
    "ESCORTS": "Escorts Kubota Limited",
    "LLOYDSME": "Lloyds Metals And Energy Limited",
    "GLAND": "Gland Pharma Limited",
    "IGL": "Indraprastha Gas Limited",
    "NAM-INDIA": "Nippon Life India Asset Management Limited",
    "APOLLOTYRE": "Apollo Tyres Limited",
    "JUBLFOOD": "Jubilant Foodworks Limited",
    "POWERINDIA": "Hitachi Energy India Limited",
    "MSUMI": "Motherson Sumi Wiring India Limited",
    "BANDHANBNK": "Bandhan Bank Limited",
    "DEEPAKNTR": "Deepak Nitrite Limited",
    "ZFCVINDIA": "ZF Commercial Vehicle Control Systems India Limited",
    "AJANTPHARM": "Ajanta Pharma Limited",
    "KPRMILL": "K.P.R. Mill Limited",
    "SYNGENE": "Syngene International Limited",
    "EIHOTEL": "EIH Limited",
    "APARINDS": "Apar Industries Limited",
    "NATIONALUM": "National Aluminium Company Limited",
    "TATACHEM": "Tata Chemicals Limited",
    "GLENMARK": "Glenmark Pharmaceuticals Limited",
    "HINDCOPPER": "Hindustan Copper Limited",
    "GODREJIND": "Godrej Industries Limited",
    "NH": "Narayana Hrudayalaya Ltd.",
    "BLUESTARCO": "Blue Star Limited",
    "EXIDEIND": "Exide Industries Limited",
    "ENDURANCE": "Endurance Technologies Limited",
    "JBCHEPHARM": "JB Chemicals & Pharmaceuticals Limited",
    "PAYTM": "One 97 Communications Limited",
    "ANGELONE": "Angel One Limited",
    "MOTILALOFS": "Motilal Oswal Financial Services Limited",
    "ITI": "ITI Limited",
    "360ONE": "360 ONE WAM LIMITED",
    "CARBORUNIV": "Carborundum Universal Limited",
    "AARTIIND": "Aarti Industries Limited",
    "SUNTV": "Sun TV Network Limited",
    "KIOCL": "KIOCL Limited",
    "ISEC": "ICICI Securities Limited",
    "RADICO": "Radico Khaitan Limited",
    "SUNDRMFAST": "Sundram Fasteners Limited",
    "CREDITACC": "CREDITACCESS GRAMEEN LIMITED",
    "COCHINSHIP": "Cochin Shipyard Limited",
    "HATSUN": "Hatsun Agro Product Limited",
    "MANYAVAR": "Vedant Fashions Limited",
    "CYIENT": "Cyient Limited",
    "GET&D": "GE T&D India Limited",
    "BRIGADE": "Brigade Enterprises Limited",
    "TIMKEN": "Timken India Limited",
    "NBCC": "NBCC (India) Limited",
    "JBMA": "JBM Auto Limited",
    "GILLETTE": "Gillette India Limited",
    "KANSAINER": "Kansai Nerolac Paints Limited",
    "LAURUSLABS": "Laurus Labs Limited",
    "GRINDWELL": "Grindwell Norton Limited",
    "FIVESTAR": "Five-Star Business Finance Limited",
    "SWANENERGY": "Swan Energy Limited",
    "CHOLAHLDNG": "Cholamandalam Financial Holdings Limited",
    "IRCON": "Ircon International Limited",
    "SKFINDIA": "SKF India Limited",
    "BSOFT": "BIRLASOFT LIMITED",
    "ASTERDM": "Aster DM Healthcare Limited",
    "RELAXO": "Relaxo Footwears Limited",
    "SONATSOFTW": "Sonata Software Limited",
    "GSPL": "Gujarat State Petronet Limited",
    "RATNAMANI": "Ratnamani Metals & Tubes Limited",
    "ABFRL": "Aditya Birla Fashion and Retail Limited",
    "APLLTD": "Alembic Pharmaceuticals Limited",
    "PFIZER": "Pfizer Limited",
    "RAMCOCEM": "The Ramco Cements Limited",
    "SIGNATURE": "Signatureglobal (India) Limited",
    "PEL": "Piramal Enterprises Limited",
    "ELGIEQUIP": "Elgi Equipments Limited",
    "LALPATHLAB": "Dr. Lal Path Labs Ltd.",
    "EMAMILTD": "Emami Limited",
    "SANOFI": "Sanofi India Limited",
    "JYOTICNC": "Jyoti CNC Automation Limited",
    "TRIDENT": "Trident Limited",
    "CASTROLIND": "Castrol India Limited",
    "KAJARIACER": "Kajaria Ceramics Limited",
    "KAYNES": "Kaynes Technology India Limited",
    "CENTURYTEX": "Century Textiles & Industries Limited",
    "CHALET": "Chalet Hotels Limited",
    "DEVYANI": "Devyani International Limited",
    "CDSL": "Central Depository Services (India) Limited",
    "KEC": "KEC International Limited",
    "SCHNEIDER": "Schneider Electric Infrastructure Limited",
    "IDFC": "IDFC Limited",
    "BATAINDIA": "Bata India Limited",
    "CIEINDIA": "CIE Automotive India Limited",
    "KPIL": "Kalpataru Projects International Limited",
    "RRKABEL": "R R Kabel Limited",
    "SUMICHEM": "Sumitomo Chemical India Limited",
    "NATCOPHARM": "Natco Pharma Limited",
    "SUVENPHAR": "Suven Pharmaceuticals Limited",
    "CROMPTON": "Crompton Greaves Consumer Electricals Limited",
    "TRITURBINE": "Triveni Turbine Limited",
    "PPLPHARMA": "Piramal Pharma Limited",
    "INOXWIND": "Inox Wind Limited",
    "ACE": "Action Construction Equipment Limited",
    "ATUL": "Atul Limited",
    "CGCL": "Capri Global Capital Limited",
    "TVSHLTD": "TVS Holdings Limited",
    "SHYAMMETL": "Shyam Metalics and Energy Limited",
    "NUVAMA": "Nuvama Wealth Management Limited",
    "KIMS": "Krishna Institute of Medical Sciences Limited",
    "CELLO": "Cello World Limited",
    "PNBHOUSING": "PNB Housing Finance Limited",
    "REDINGTON": "Redington Limited",
    "LAXMIMACH": "Lakshmi Machine Works Limited",
    "JYOTHYLAB": "Jyothy Labs Limited",
    "CESC": "CESC Limited",
    "GODFRYPHLP": "Godfrey Phillips India Limited",
    "NSLNISP": "NMDC Steel Limited",
    "RITES": "RITES Limited",
    "CONCORDBIO": "Concord Biotech Limited",
    "INDIAMART": "Indiamart Intermesh Limited",
    "AEGISCHEM": "Aegis Logistics Limited",
    "OLECTRA": "Olectra Greentech Limited",
    "WHIRLPOOL": "Whirlpool of India Limited",
    "ANANDRATHI": "Anand Rathi Wealth Limited",
    "NAVINFLUOR": "Navin Fluorine International Limited",
    "JWL": "Jupiter Wagons Limited",
    "APTUS": "Aptus Value Housing Finance India Limited",
    "FINCABLES": "Finolex Cables Limited",
    "FINPIPE": "Finolex Industries Limited",
    "POLYMED": "Poly Medicure Limited",
    "VINATIORGA": "Vinati Organics Limited",
    "INTELLECT": "Intellect Design Arena Limited",
    "JAIBALAJI": "Jai Balaji Industries Limited",
    "J&KBANK": "The Jammu & Kashmir Bank Limited",
    "KARURVYSYA": "Karur Vysya Bank Limited",
    "BLUEDART": "Blue Dart Express Limited",
    "MANAPPURAM": "Manappuram Finance Limited",
    "AFFLE": "Affle (India) Limited",
    "NCC": "NCC Limited",
    "RBLBANK": "RBL Bank Limited",
    "TTML": "Tata Teleservices (Maharashtra) Limited",
    "BASF": "BASF India Limited",
    "VGUARD": "V-Guard Industries Limited",
    "CAMS": "Computer Age Management Services Limited",
    "GESHIP": "The Great Eastern Shipping Company Limited",
    "CENTURYPLY": "Century Plyboards (India) Limited",
    "CLEAN": "Clean Science and Technology Limited",
    "JINDALSAW": "Jindal Saw Limited",
    "FSL": "Firstsource Solutions Limited",
    "ZENSARTECH": "Zensar Technologies Limited",
    "SOBHA": "Sobha Limited",
    "CHAMBLFERT": "Chambal Fertilizers & Chemicals Limited",
    "DATAPATTNS": "Data Patterns (India) Limited",
    "CHENNPETRO": "Chennai Petroleum Corporation Limited",
    "WELCORP": "Welspun Corp Limited",
    "MGL": "Mahanagar Gas Limited",
    "KSB": "Ksb Limited",
    "WELSPUNLIV": "Welspun Living Limited",
    "HSCL": "Himadri Speciality Chemical Limited",
    "DCMSHRIRAM": "DCM Shriram Limited",
    "ASTRAZEN": "AstraZeneca Pharma India Limited",
    "ZEEL": "Zee Entertainment Enterprises Limited",
    "BEML": "BEML Limited",
    "HFCL": "HFCL Limited",
    "RAINBOW": "Rainbow Childrens Medicare Limited",
    "ABSLAMC": "Aditya Birla Sun Life AMC Limited",
    "HONASA": "Honasa Consumer Limited",
    "ASAHIINDIA": "Asahi India Glass Limited",
    "PVRINOX": "PVR INOX Limited",
    "ARE&M": "Amara Raja Energy & Mobility Limited",
    "IIFL": "IIFL Finance Limited",
    "BLS": "BLS International Services Limited",
    "ALOKINDS": "Alok Industries Limited",
    "VTL": "Vardhman Textiles Limited",
    "GRINFRA": "G R Infraprojects Limited",
    "HBLPOWER": "HBL Power Systems Limited",
    "WESTLIFE": "WESTLIFE FOODWORLD LIMITED",
    "RKFORGE": "Ramkrishna Forgings Limited",
    "KIRLOSENG": "Kirloskar Oil Engines Limited",
    "TITAGARH": "TITAGARH RAIL SYSTEMS LIMITED",
    "FINEORG": "Fine Organic Industries Limited",
    "AMBER": "Amber Enterprises India Limited",
    "BIKAJI": "Bikaji Foods International Limited",
    "SWSOLAR": "Sterling and Wilson Renewable Energy Limited",
    "RAYMOND": "Raymond Limited",
    "IEX": "Indian Energy Exchange Limited",
    "SPARC": "Sun Pharma Advanced Research Company Limited",
    "GRAPHITE": "Graphite India Limited",
    "SPLPETRO": "Supreme Petrochem Limited",
    "RAILTEL": "Railtel Corporation Of India Limited",
    "INGERRAND": "Ingersoll Rand (India) Limited",
    "ECLERX": "eClerx Services Limited",
    "JUNIPER": "Juniper Hotels Limited",
    "ERIS": "Eris Lifesciences Limited",
    "RHIM": "RHI MAGNESITA INDIA LIMITED",
    "ENGINERSIN": "Engineers India Limited",
    "MAHSEAMLES": "Maharashtra Seamless Limited",
    "HAPPSTMNDS": "Happiest Minds Technologies Limited",
    "JKTYRE": "JK Tyre & Industries Limited",
    "TEJASNET": "Tejas Networks Limited",
    "PNCINFRA": "PNC Infratech Limited",
    "NEWGEN": "Newgen Software Technologies Limited",
    "INOXINDIA": "INOX India Limited",
    "TANLA": "Tanla Platforms Limited",
    "BIRLACORPN": "Birla Corporation Limited",
    "BBTC": "Bombay Burmah Trading Corporation Limited",
    "GMDCLTD": "Gujarat Mineral Development Corporation Limited",
    "NUVOCO": "Nuvoco Vistas Corporation Limited",
    "AKZOINDIA": "Akzo Nobel India Limited",
    "CEATLTD": "CEAT Limited",
    "RPOWER": "Reliance Power Limited",
    "RELINFRA": "Reliance Infrastructure Limited",
    "GPIL": "Godawari Power And Ispat limited",
    "ELECON": "Elecon Engineering Company Limited",
    "ANANTRAJ": "Anant Raj Limited",
    "ELECTCAST": "Electrosteel Castings Limited",
    "DBREALTY": "D B Realty Limited",
    "EQUITASBNK": "Equitas Small Finance Bank Limited",
    "KFINTECH": "Kfin Technologies Limited",
    "BAJAJELEC": "Bajaj Electricals Limited",
    "LATENTVIEW": "Latent View Analytics Limited",
    "JPPOWER": "Jaiprakash Power Ventures Limited",
    "GRANULES": "Granules India Limited",
    "AAVAS": "Aavas Financiers Limited",
    "AETHER": "Aether Industries Limited",
    "UTIAMC": "UTI Asset Management Company Limited",
    "LEMONTREE": "Lemon Tree Hotels Limited",
    "JKLAKSHMI": "JK Lakshmi Cement Limited",
    "GPPL": "Gujarat Pipavav Port Limited",
    "SFL": "Sheela Foam Limited",
    "PCBL": "PCBL LIMITED",
    "MAPMYINDIA": "C.E. Info Systems Limited",
    "ROUTE": "ROUTE MOBILE LIMITED",
    "CANFINHOME": "Can Fin Homes Limited",
    "CUB": "City Union Bank Limited",
    "SAPPHIRE": "Sapphire Foods India Limited",
    "CAPLIPOINT": "Caplin Point Laboratories Limited",
    "MINDACORP": "Minda Corporation Limited",
    "MMTC": "MMTC Limited",
    "PTCIL": "PTC Industries Limited",
    "IFCI": "IFCI Limited",
    "PRAJIND": "Praj Industries Limited",
    "VOLTAMP": "Voltamp Transformers Limited",
    "SCI": "Shipping Corporation Of India Limited",
    "USHAMART": "Usha Martin Limited",
    "EIDPARRY": "EID Parry India Limited",
    "RTNINDIA": "RattanIndia Enterprises Limited",
    "ANURAS": "Anupam Rasayan India Limited",
    "GLS": "Glenmark Life Sciences Limited",
    "DOMS": "DOMS Industries Limited",
    "INFIBEAM": "Infibeam Avenues Limited",
    "FORCEMOT": "FORCE MOTORS LTD",
    "ZYDUSWELL": "Zydus Wellness Limited",
    "STARCEMENT": "Star Cement Limited",
    "GODREJAGRO": "Godrej Agrovet Limited",
    "TTKPRESTIG": "TTK Prestige Limited",
    "ALKYLAMINE": "Alkyl Amines Chemicals Limited",
    "GNFC": "Gujarat Narmada Valley Fertilizers and Chemicals Limited",
    "KPIGREEN": "KPI Green Energy Limited",
    "CRAFTSMAN": "Craftsman Automation Limited",
    "MAHLIFE": "Mahindra Lifespace Developers Limited",
    "REDTAPE": "Redtape Limited",
    "JUBLPHARMA": "Jubilant Pharmova Limited",
    "NETWEB": "Netweb Technologies India Limited",
    "NETWORK18": "Network18 Media & Investments Limited",
    "PRSMJOHNSN": "Prism Johnson Limited",
    "METROPOLIS": "Metropolis Healthcare Limited",
    "CERA": "Cera Sanitaryware Limited",
    "SBFC": "SBFC Finance Limited",
    "GRSE": "Garden Reach Shipbuilders & Engineers Limited",
    "KIRLOSBROS": "Kirloskar Brothers Limited",
    "UJJIVANSFB": "Ujjivan Small Finance Bank Limited",
    "SHRIPISTON": "Shriram Pistons & Rings Limited",
    "RENUKA": "Shree Renuka Sugars Limited",
    "RATEGAIN": "Rategain Travel Technologies Limited",
    "WOCKPHARMA": "Wockhardt Limited",
    "SAFARI": "Safari Industries (India) Limited",
    "HAPPYFORGE": "Happy Forgings Limited",
    "TECHNOE": "Techno Electric & Engineering Company Limited",
    "SHOPERSTOP": "Shoppers Stop Limited",
    "IBULHSGFIN": "Indiabulls Housing Finance Limited",
    "SYRMA": "Syrma SGS Technology Limited",
    "TEGA": "Tega Industries Limited",
    "ACI": "Archean Chemical Industries Limited",
    "MEDPLUS": "Medplus Health Services Limited",
    "MAHSCOOTER": "Maharashtra Scooters Limited",
    "NEULANDLAB": "Neuland Laboratories Limited",
    "AZAD": "Azad Engineering Limited",
    "ESABINDIA": "Esab India Limited",
    "GALAXYSURF": "Galaxy Surfactants Limited",
    "ZENTEC": "Zen Technologies Limited",
    "JSWHL": "JSW Holdings Limited",
    "TV18BRDCST": "TV18 Broadcast Limited",
    "HOMEFIRST": "Home First Finance Company India Limited",
    "MHRIL": "Mahindra Holidays & Resorts India Limited",
    "POWERMECH": "Power Mech Projects Limited",
    "KTKBANK": "The Karnataka Bank Limited",
    "JLHL": "Jupiter Life Line Hospitals Limited",
    "MASTEK": "Mastek Limited",
    "PGHL": "Procter & Gamble Health Limited",
    "THOMASCOOK": "Thomas Cook  (India)  Limited",
    "CCL": "CCL Products (India) Limited",
    "GSFC": "Gujarat State Fertilizers & Chemicals Limited",
    "RAJESHEXPO": "Rajesh Exports Limited",
    "QUESS": "Quess Corp Limited",
    "VARROC": "Varroc Engineering Limited",
    "TMB": "Tamilnad Mercantile Bank Limited",
    "MANINFRA": "Man Infraconstruction Limited",
    "EASEMYTRIP": "Easy Trip Planners Limited",
    "VIPIND": "VIP Industries Limited",
    "IONEXCHANG": "ION Exchange (India) Limited",
    "RESPONIND": "Responsive Industries Limited",
    "MIDHANI": "Mishra Dhatu Nigam Limited",
    "EMIL": "Electronics Mart India Limited",
    "GAEL": "Gujarat Ambuja Exports Limited",
    "BALRAMCHIN": "Balrampur Chini Mills Limited",
    "STAR": "Strides Pharma Science Limited",
    "JUBLINGREA": "Jubilant Ingrevia Limited",
    "SARDAEN": "Sarda Energy & Minerals Limited",
    "JMFINANCIL": "JM Financial Limited",
    "SOUTHBANK": "The South Indian Bank Limited",
    "HEG": "HEG Limited",
    "CHEMPLASTS": "Chemplast Sanmar Limited",
    "ARVIND": "Arvind Limited",
    "RCF": "Rashtriya Chemicals and Fertilizers Limited",
    "NAVA": "NAVA LIMITED",
    "ALLCARGO": "Allcargo Logistics Limited",
    "ICIL": "Indo Count Industries Limited",
    "IWEL": "Inox Wind Energy Limited",
    "KNRCON": "KNR Constructions Limited",
    "FDC": "FDC Limited",
    "RELIGARE": "Religare Enterprises Limited",
    "GRAVITA": "Gravita India Limited",
    "RUSTOMJEE": "Keystone Realtors Limited",
    "MARKSANS": "Marksans Pharma Limited",
    "NIITMTS": "NIIT Learning Systems Limited",
    "AHLUCONT": "Ahluwalia Contracts (India) Limited",
    "JUSTDIAL": "Just Dial Limited",
    "TRIVENI": "Triveni Engineering & Industries Limited",
    "TVSSCS": "TVS Supply Chain Solutions Limited",
    "GARFIBRES": "Garware Technical Fibres Limited",
    "VESUVIUS": "Vesuvius India Limited",
    "SAREGAMA": "Saregama India Limited",
    "DBL": "Dilip Buildcon Limited",
    "INDIASHLTR": "India Shelter Finance Corporation Limited",
    "BLUEJET": "Blue Jet Healthcare Limited",
    "BALAMINES": "Balaji Amines Limited",
    "ISGEC": "Isgec Heavy Engineering Limited",
    "AVANTIFEED": "Avanti Feeds Limited",
    "INDIACEM": "The India Cements Limited",
    "BECTORFOOD": "Mrs. Bectors Food Specialities Limited",
    "CAMPUS": "Campus Activewear Limited",
    "LTFOODS": "LT Foods Limited",
    "VIJAYA": "Vijaya Diagnostic Centre Limited",
    "GOCOLORS": "Go Fashion (India) Limited",
    "BORORENEW": "BOROSIL RENEWABLES LIMITED",
    "LXCHEM": "Laxmi Organic Industries Limited",
    "GREENLAM": "Greenlam Industries Limited",
    "DEEPAKFERT": "Deepak Fertilizers and Petrochemicals Corporation Limited",
    "CMSINFO": "CMS Info Systems Limited",
    "KRBL": "KRBL Limited",
    "ETHOSLTD": "Ethos Limited",
    "TEXRAIL": "Texmaco Rail & Engineering Limited",
    "TCI": "Transport Corporation of India Limited",
    "IBREALEST": "Indiabulls Real Estate Limited",
    "JINDWORLD": "Jindal Worldwide Limited",
    "EMUDHRA": "eMudhra Limited",
    "PDSL": "PDS Limited",
    "GANESHHOUC": "Ganesh Housing Corporation Limited",
    "CSBBANK": "CSB Bank Limited",
    "SHAREINDIA": "Share India Securities Limited",
    "IFBIND": "IFB Industries Limited",
    "PRINCEPIPE": "Prince Pipes And Fittings Limited",
    "VAIBHAVGBL": "Vaibhav Global Limited",
    "ARVINDFASN": "Arvind Fashions Limited",
    "EDELWEISS": "Edelweiss Financial Services Limited",
    "SENCO": "Senco Gold Limited",
    "SPANDANA": "Spandana Sphoorty Financial Limited",
    "INDIGOPNTS": "Indigo Paints Limited",
    "GENUSPOWER": "Genus Power Infrastructures Limited",
    "SYMPHONY": "Symphony Limited",
    "HGINFRA": "H.G. Infra Engineering Limited",
    "TIPSINDLTD": "TIPS Industries Limited",
    "SIS": "SIS LIMITED",
    "MSTCLTD": "Mstc Limited",
    "NESCO": "Nesco Limited",
    "SANGHVIMOV": "Sanghvi Movers Limited",
    "SANDUMA": "Sandur Manganese & Iron Ores Limited",
    "UJJIVAN": "Ujjivan Financial Services Limited",
    "ITDCEM": "ITD Cementation India Limited",
    "CYIENTDLM": "Cyient DLM Limited",
    "EPL": "EPL Limited",
    "SUPRAJIT": "Suprajit Engineering Limited",
    "SUNTECK": "Sunteck Realty Limited",
    "HEMIPROP": "Hemisphere Properties India Limited",
    "MOIL": "MOIL Limited",
    "TIMETECHNO": "Time Technoplast Limited",
    "ASTRAMICRO": "Astra Microwave Products Limited",
    "TRIL": "Transformers And Rectifiers (India) Limited",
    "WONDERLA": "Wonderla Holidays Limited",
    "ASKAUTOLTD": "ASK Automotive Limited",
    "LLOYDSENGG": "LLOYDS ENGINEERING WORKS LIMITED",
    "GMMPFAUDLR": "GMM Pfaudler Limited",
    "SURYAROSNI": "Surya Roshni Limited",
    "VSTIND": "VST Industries Limited",
    "PTC": "PTC India Limited",
    "JKPAPER": "JK Paper Limited",
    "SANSERA": "Sansera Engineering Limited",
    "CHOICEIN": "Choice International Limited",
    "AURIONPRO": "Aurionpro Solutions Limited",
    "PAISALO": "Paisalo Digital Limited",
    "ITDC": "India Tourism Development Corporation Limited",
    "HNDFDS": "Hindustan Foods Limited",
    "PARADEEP": "Paradeep Phosphates Limited",
    "KESORAMIND": "Kesoram Industries Limited",
    "HCC": "Hindustan Construction Company Limited",
    "ORCHPHARMA": "Orchid Pharma Limited",
    "JAMNAAUTO": "Jamna Auto Industries Limited",
    "ICRA": "ICRA Limited",
    "RSYSTEMS": "R Systems International Limited",
    "PRUDENT": "Prudent Corporate Advisory Services Limited",
    "MTARTECH": "Mtar Technologies Limited",
    "UTKARSHBNK": "Utkarsh Small Finance Bank Limited",
    "RAIN": "Rain Industries Limited",
    "DYNAMATECH": "Dynamatic Technologies Limited",
    "JAICORPLTD": "Jai Corp Limited",
    "RBA": "Restaurant Brands Asia Limited",
    "GATEWAY": "Gateway Distriparks Limited",
    "PURVA": "Puravankara Limited",
    "GUJALKALI": "Gujarat Alkalies and Chemicals Limited",
    "NAZARA": "Nazara Technologies Limited",
    "RALLIS": "Rallis India Limited",
    "VRLLOG": "VRL Logistics Limited",
    "GABRIEL": "Gabriel India Limited",
    "DODLA": "Dodla Dairy Limited",
    "JKIL": "J.Kumar Infraprojects Limited",
    "ROLEXRINGS": "Rolex Rings Limited",
    "WABAG": "VA Tech Wabag Limited",
    "PRICOLLTD": "Pricol Limited",
    "HCG": "Healthcare Global Enterprises Limited",
    "AGI": "AGI Greenpac Limited",
    "DBCORP": "D.B.Corp Limited",
    "FUSION": "Fusion Micro Finance Limited",
    "DHANUKA": "Dhanuka Agritech Limited",
    "MASFIN": "MAS Financial Services Limited",
    "SULA": "Sula Vineyards Limited",
    "TDPOWERSYS": "TD Power Systems Limited",
    "GALLANTT": "Gallantt Ispat Limited",
    "JAYNECOIND": "Jayaswal Neco Industries Limited",
    "GULFOILLUB": "Gulf Oil Lubricants India Limited",
    "SAMHI": "Samhi Hotels Limited",
    "TEAMLEASE": "Teamlease Services Limited",
    "KIRLPNU": "Kirloskar Pneumatic Company Limited",
    "EPIGRAL": "Epigral Limited",
    "TIIL": "Technocraft Industries (India) Limited",
    "GOPAL": "Gopal Snacks Limited",
    "JTEKTINDIA": "Jtekt India Limited",
    "HEIDELBERG": "HeidelbergCement India Limited",
    "SUNDARMHLD": "Sundaram Finance Holdings Limited",
    "RTNPOWER": "RattanIndia Power Limited",
    "STLTECH": "Sterlite Technologies Limited",
    "JPASSOCIAT": "Jaiprakash Associates Limited",
    "PATELENG": "Patel Engineering Limited",
    "ASHOKA": "Ashoka Buildcon Limited",
    "SINDHUTRAD": "Sindhu Trade Links Limited",
    "PGEL": "PG Electroplast Limited",
    "NFL": "National Fertilizers Limited",
    "ENTERO": "Entero Healthcare Solutions Limited",
    "JSFB": "Jana Small Finance Bank Limited",
    "GOKEX": "Gokaldas Exports Limited",
    "BANCOINDIA": "Banco Products (I) Limited",
    "VMART": "V-Mart Retail Limited",
    "SHANTIGEAR": "Shanthi Gears Limited",
    "GHCL": "GHCL Limited",
    "SUDARSCHEM": "Sudarshan Chemical Industries Limited",
    "WELENT": "Welspun Enterprises Limited",
    "FEDFINA": "Fedbank Financial Services Limited",
    "NOCIL": "NOCIL Limited",
    "TARC": "TARC Limited",
    "KKCL": "Kewal Kiran Clothing Limited",
    "ORIENTELEC": "Orient Electric Limited",
    "BOROLTD": "Borosil Limited",
    "KIRLOSIND": "Kirloskar Industries Limited",
    "BALMLAWRIE": "Balmer Lawrie & Company Limited",
    "FCL": "Fineotex Chemical Limited",
    "GRWRHITECH": "Garware Hi-Tech Films Limited",
    "SHARDAMOTR": "Sharda Motor Industries Limited",
    "PARKHOTELS": "Apeejay Surrendra Park Hotels Limited",
    "MAXESTATES": "Max Estates Limited",
    "TI": "Tilaknagar Industries Limited",
    "AMIORG": "Ami Organics Limited",
    "ORIENTCEM": "Orient Cement Limited",
    "SHILPAMED": "Shilpa Medicare Limited",
    "AARTIDRUGS": "Aarti Drugs Limited",
    "LGBBROSLTD": "LG Balakrishnan & Bros Limited",
    "AARTIPHARM": "Aarti Pharmalabs Limited",
    "TCIEXP": "TCI Express Limited",
    "WSTCSTPAPR": "West Coast Paper Mills Limited",
    "ADVENZYMES": "Advanced Enzyme Technologies Limited",
    "PRIVISCL": "Privi Speciality Chemicals Limited",
    "GREENPANEL": "Greenpanel Industries Limited",
    "VENUSPIPES": "Venus Pipes & Tubes Limited",
    "BBOX": "Black Box Limited",
    "IIFLSEC": "IIFL Securities Limited",
    "PILANIINVS": "Pilani Investment and Industries Corporation Limited",
    "ROSSARI": "Rossari Biotech Limited",
    "KSL": "Kalyani Steels Limited",
    "DCBBANK": "DCB Bank Limited",
    "IMAGICAA": "Imagicaaworld Entertainment Limited",
    "BAJAJHIND": "Bajaj Hindusthan Sugar Limited",
    "DCAL": "Dishman Carbogen Amcis Limited",
    "HARSHA": "Harsha Engineers International Limited",
    "BBL": "Bharat Bijlee Limited",
    "YATHARTH": "Yatharth Hospital & Trauma Care Services Limited",
    "ORISSAMINE": "The Orissa Minerals Development Company Limited",
    "THANGAMAYL": "Thangamayil Jewellery Limited",
    "ZAGGLE": "Zaggle Prepaid Ocean Services Limited",
    "BHARATRAS": "Bharat Rasayan Limited",
    "KOLTEPATIL": "Kolte - Patil Developers Limited",
    "KSCL": "Kaveri Seed Company Limited",
    "MEDIASSIST": "Medi Assist Healthcare Services Limited",
    "INOXGREEN": "Inox Green Energy Services Limited",
    "HATHWAY": "Hathway Cable & Datacom Limited",
    "SSWL": "Steel Strips Wheels Limited",
    "UNICHEMLAB": "Unichem Laboratories Limited",
    "CIGNITITEC": "Cigniti Technologies Limited",
    "IMFA": "Indian Metals & Ferro Alloys Limited",
    "ASHAPURMIN": "Ashapura Minechem Limited",
    "HGS": "Hinduja Global Solutions Limited",
    "MUTHOOTMF": "Muthoot Microfin Limited",
    "SUBROS": "Subros Limited",
    "RAMKY": "Ramky Infrastructure Limited",
    "SUNFLAG": "Sunflag Iron And Steel Company Limited",
    "CARERATING": "CARE Ratings Limited",
    "GENSOL": "Gensol Engineering Limited",
    "SKIPPER": "Skipper Limited",
    "LAOPALA": "La Opala RG Limited",
    "LUMAXTECH": "Lumax Auto Technologies Limited",
    "DCXINDIA": "DCX Systems Limited",
    "BOMDYEING": "Bombay Dyeing & Mfg Company Limited",
    "HIKAL": "Hikal Limited",
    "JISLJALEQS": "Jain Irrigation Systems Limited",
    "CUPID": "Cupid Limited",
    "AVALON": "Avalon Technologies Limited",
    "LUXIND": "Lux Industries Limited",
    "NUCLEUS": "Nucleus Software Exports Limited",
    "TASTYBITE": "Tasty Bite Eatables Limited",
    "SOTL": "Savita Oil Technologies Limited",
    "ARVSMART": "Arvind SmartSpaces Limited",
    "SANDHAR": "Sandhar Technologies Limited",
    "SALASAR": "Salasar Techno Engineering Limited",
    "NEOGEN": "Neogen Chemicals Limited",
    "DATAMATICS": "Datamatics Global Services Limited",
    "JTLIND": "JTL INDUSTRIES LIMITED",
    "ANUP": "The Anup Engineering Limited",
    "HERITGFOOD": "Heritage Foods Limited",
    "THYROCARE": "Thyrocare Technologies Limited",
    "VADILALIND": "Vadilal Industries Limited",
    "NAVNETEDUL": "Navneet Education Limited",
    "DISHTV": "Dish TV India Limited",
    "KDDL": "KDDL Limited",
    "KALAMANDIR": "Sai Silks (Kalamandir) Limited",
    "LANDMARK": "Landmark Cars Limited",
    "INDOCO": "Indoco Remedies Limited",
    "BAJAJCON": "Bajaj Consumer Care Limited",
    "TVSSRICHAK": "TVS Srichakra Limited",
    "CARTRADE": "Cartrade Tech Limited",
    "SBCL": "Shivalik Bimetal Controls Limited",
    "FIEMIND": "Fiem Industries Limited",
    "PRAKASH": "Prakash Industries Limited",
    "DELTACORP": "Delta Corp Limited",
    "RAJRATAN": "Rajratan Global Wire Limited",
    "IDEAFORGE": "Ideaforge Technology Limited",
    "MAHLOG": "Mahindra Logistics Limited",
    "PFOCUS": "Prime Focus Limited",
    "GREAVESCOT": "Greaves Cotton Limited",
    "DOLLAR": "Dollar Industries Limited",
    "UFLEX": "UFLEX Limited",
    "UNITECH": "Unitech Limited",
    "BFUTILITIE": "BF Utilities Limited",
    "SHARDACROP": "Sharda Cropchem Limited",
    "BANARISUG": "Bannari Amman Sugars Limited",
    "SEQUENT": "Sequent Scientific Limited",
    "GREENPLY": "Greenply Industries Limited",
    "MAITHANALL": "Maithan Alloys Limited",
    "SHK": "S H Kelkar and Company Limited",
    "SUNCLAY": "Sundaram Clayton Limited",
    "GUFICBIO": "Gufic Biosciences Limited",
    "BLSE": "BLS E-Services Limited",
    "DIACABS": "Diamond Power Infrastructure Limited",
    "ESAFSFB": "ESAF Small Finance Bank Limited",
    "VSTTILLERS": "V.S.T Tillers Tractors Limited",
    "HLEGLAS": "HLE Glascoat Limited",
    "BCG": "Brightcom Group Limited",
    "GOODLUCK": "Goodluck India Limited",
    "SWARAJENG": "Swaraj Engines Limited",
    "SEAMECLTD": "Seamec Limited",
    "SMLISUZU": "SML Isuzu Limited",
    "ASHIANA": "Ashiana Housing Limited",
    "DALMIASUG": "Dalmia Bharat Sugar and Industries Limited",
    "HINDWAREAP": "Hindware Home Innovation Limited",
    "SAGCEM": "Sagar Cements Limited",
    "SAKSOFT": "Saksoft Limited",
    "APOLLO": "Apollo Micro Systems Limited",
    "SUPRIYA": "Supriya Lifescience Limited",
    "AUTOAXLES": "Automotive Axles Limited",
    "STYLAMIND": "Stylam Industries Limited",
    "FLAIR": "Flair Writing Industries Limited",
    "VINDHYATEL": "Vindhya Telelinks Limited",
    "CARYSIL": "CARYSIL LIMITED",
    "THEJO": "Thejo Engineering Limited",
    "MPSLTD": "MPS Limited",
    "MARATHON": "Marathon Nextgen Realty Limited",
    "ISMTLTD": "ISMT Limited",
    "FILATEX": "Filatex India Limited",
    "NRBBEARING": "NRB Bearing Limited",
    "JCHAC": "Johnson Controls - Hitachi Air Conditioning India Limited",
    "MOLDTKPAC": "Mold-Tek Packaging Limited",
    "DREAMFOLKS": "Dreamfolks Services Limited",
    "GMRP&UI": "GMR Power and Urban Infra Limited",
    "SHALBY": "Shalby Limited",
    "INNOVACAP": "Innova Captab Limited",
    "PFS": "PTC India Financial Services Limited",
    "AJMERA": "Ajmera Realty & Infra India Limited",
    "HMAAGRO": "HMA Agro Industries Limited",
    "NILKAMAL": "Nilkamal Limited",
    "RPGLIFE": "RPG Life Sciences Limited",
    "TATVA": "Tatva Chintan Pharma Chem Limited",
    "STYRENIX": "Styrenix Performance Materials Limited",
    "QUICKHEAL": "Quick Heal Technologies Limited",
    "ACCELYA": "Accelya Solutions India Limited",
    "REPCOHOME": "Repco Home Finance Limited",
    "PCJEWELLER": "PC Jeweller Limited",
    "APOLLOPIPE": "Apollo Pipes Limited",
    "GANECOS": "Ganesha Ecosphere Limited",
    "PARAGMILK": "Parag Milk Foods Limited",
    "BAJEL": "Bajel Projects Limited",
    "PSPPROJECT": "PSP Projects Limited",
    "GIPCL": "Gujarat Industries Power Company Limited",
    "XPROINDIA": "Xpro India Limited",
    "PITTIENG": "Pitti Engineering Limited",
    "SHAKTIPUMP": "Shakti Pumps (India) Limited",
    "TIDEWATER": "Tide Water Oil Company (India) Limited",
    "SHAILY": "Shaily Engineering Plastics Limited",
    "EVEREADY": "Eveready Industries India Limited",
    "CONFIPET": "Confidence Petroleum India Limited",
    "POLYPLEX": "Polyplex Corporation Limited",
    "TAJGVK": "Taj GVK Hotels & Resorts Limited",
    "TIRUMALCHM": "Thirumalai Chemicals Limited",
    "SPECTRUM": "Spectrum Electrical Industries Limited",
    "PARAS": "Paras Defence and Space Technologies Limited",
    "EXICOM": "Exicom Tele-Systems Limited",
    "PGIL": "Pearl Global Industries Limited",
    "MANORAMA": "Manorama Industries Limited",
    "SOMANYCERA": "Somany Ceramics Limited",
    "KINGFA": "Kingfa Science & Technology (India) Limited",
    "FINOPB": "Fino Payments Bank Limited",
    "UNIPARTS": "Uniparts India Limited",
    "INDOSTAR": "IndoStar Capital Finance Limited",
    "DIVGIITTS": "Divgi Torqtransfer Systems Limited",
    "HINDOILEXP": "Hindustan Oil Exploration Company Limited",
    "SEPC": "SEPC Limited",
    "INDIAGLYCO": "India Glycols Limited",
    "IPL": "India Pesticides Limited",
    "GENESYS": "Genesys International Corporation Limited",
    "SANGHIIND": "Sanghi Industries Limited",
    "BSHSL": "Bombay Super Hybrid Seeds Limited",
    "SATIN": "Satin Creditcare Network Limited",
    "AXISCADES": "AXISCADES Technologies Limited",
    "ARTEMISMED": "Artemis Medicare Services Limited",
    "SASKEN": "Sasken Technologies Limited",
    "EIHAHOTELS": "EIH Associated Hotels Limited",
    "DHANI": "Dhani Services Limited",
    "PRECWIRE": "Precision Wires India Limited",
    "VIDHIING": "Vidhi Specialty Food Ingredients Limited",
    "APCOTEXIND": "Apcotex Industries Limited",
    "HUHTAMAKI": "Huhtamaki India Limited",
    "LUMAXIND": "Lumax Industries Limited",
    "GOCLCORP": "GOCL Corporation Limited",
    "WENDT": "Wendt (India) Limited",
    "DEN": "Den Networks Limited",
    "YATRA": "Yatra Online Limited",
    "HONDAPOWER": "Honda India Power Products Limited",
    "KCP": "KCP Limited",
    "EMSLIMITED": "EMS Limited",
    "JAGRAN": "Jagran Prakashan Limited",
    "SANGAMIND": "Sangam (India) Limited",
    "BEPL": "Bhansali Engineering Polymers Limited",
    "CAPACITE": "Capacit'e Infraprojects Limited",
    "NPST": "Network People Services Technologies Limited",
    "SUVEN": "Suven Life Sciences Limited",
    "MANINDS": "Man Industries (India) Limited",
    "DIAMONDYD": "Prataap Snacks Limited",
    "CENTUM": "Centum Electronics Limited",
    "VENKEYS": "Venky's (India) Limited",
    "IKIO": "IKIO Lighting Limited",
    "TCNSBRANDS": "TCNS Clothing Co. Limited",
    "OPTIEMUS": "Optiemus Infracom Limited",
    "MOREPENLAB": "Morepen Laboratories Limited",
    "MUKANDLTD": "Mukand Limited",
    "UDS": "Updater Services Limited",
    "ALEMBICLTD": "Alembic Limited",
    "RAMASTEEL": "Rama Steel Tubes Limited",
    "IOLCP": "IOL Chemicals and Pharmaceuticals Limited",
    "MBAPL": "Madhya Bharat Agro Products Limited",
    "MMFL": "MM Forgings Limited",
    "VAKRANGEE": "Vakrangee Limited",
    "TARSONS": "Tarsons Products Limited",
    "ASTEC": "Astec LifeSciences Limited",
    "JSLL": "Jeena Sikho Lifecare Limited",
    "VISHNU": "Vishnu Chemicals Limited",
    "TTKHLTCARE": "TTK Healthcare Limited",
    "MTNL": "Mahanagar Telephone Nigam Limited",
    "RPTECH": "Rashi Peripherals Limited",
    "RPSGVENT": "RPSG VENTURES LIMITED",
    "ORIENTHOT": "Oriental Hotels Limited",
    "GTLINFRA": "GTL Infrastructure Limited",
    "WEBELSOLAR": "Websol Energy System Limited",
    "JASH": "Jash Engineering Limited",
    "UGROCAP": "Ugro Capital Limited",
    "SDBL": "Som Distilleries & Breweries Limited",
    "HPL": "HPL Electric & Power Limited",
    "TCPLPACK": "TCPL Packaging Limited",
    "ADFFOODS": "ADF Foods Limited",
    "HMT": "HMT Limited",
    "MOL": "Meghmani Organics Limited",
    "MUFIN": "Mufin Green Finance Limited",
    "THEMISMED": "Themis Medicare Limited",
    "PANAMAPET": "Panama Petrochem Limited",
    "MANGLMCEM": "Mangalam Cement Limited",
    "SIGNPOST": "Signpost India Limited",
    "HITECH": "Hi-Tech Pipes Limited",
    "MAYURUNIQ": "Mayur Uniquoters Ltd",
    "SIYSIL": "Siyaram Silk Mills Limited",
    "JINDALPOLY": "Jindal Poly Films Limited",
    "KRSNAA": "Krsnaa Diagnostics Limited",
    "DEEPINDS": "Deep Industries Limited",
    "PNBGILTS": "PNB Gilts Limited",
    "HIL": "HIL Limited",
    "RICOAUTO": "Rico Auto Industries Limited",
    "BFINVEST": "BF Investment Limited",
    "GANDHAR": "Gandhar Oil Refinery (India) Limited",
    "IFGLEXPOR": "IFGL Refractories Limited",
    "BARBEQUE": "Barbeque Nation Hospitality Limited",
    "ANDHRAPAP": "ANDHRA PAPER LIMITED",
    "IRMENERGY": "IRM Energy Limited",
    "RIIL": "Reliance Industrial Infrastructure Limited",
    "SHRIRAMPPS": "Shriram Properties Limited",
    "GLOBUSSPR": "Globus Spirits Limited",
    "DREDGECORP": "Dredging Corporation of India Limited",
    "RUPA": "Rupa & Company Limited",
    "SJS": "S.J.S. Enterprises Limited",
    "PARACABLES": "Paramount Communications Limited",
    "EXPLEOSOL": "Expleo Solutions Limited",
    "PRECAM": "Precision Camshafts Limited",
    "BHARATWIRE": "Bharat Wire Ropes Limited",
    "GTPL": "GTPL Hathway Limited",
    "VPRPL": "Vishnu Prakash R Punglia Limited",
    "ADORWELD": "Ador Welding Limited",
    "DPABHUSHAN": "D. P. Abhushan Limited",
    "FOSECOIND": "Foseco India Limited",
    "SCILAL": "Shipping Corporation of India Land and Assets Limited",
    "SESHAPAPER": "Seshasayee Paper and Boards Limited",
    "JINDRILL": "Jindal Drilling And Industries Limited",
    "YASHO": "Yasho Industries Limited",
    "GREENPOWER": "Orient Green Power Company Limited",
    "NITINSPIN": "Nitin Spinners Limited",
    "GOLDIAM": "Goldiam International Limited",
    "PIXTRANS": "Pix Transmissions Limited",
    "SIGACHI": "Sigachi Industries Limited",
    "ARMANFIN": "Arman Financial Services Limited",
    "MONARCH": "Monarch Networth Capital Limited",
    "AMRUTANJAN": "Amrutanjan Health Care Limited",
    "FMGOETZE": "Federal-Mogul Goetze (India) Limited.",
    "PENIND": "Pennar Industries Limited",
    "GEPIL": "GE Power India Limited",
    "PVSL": "Popular Vehicles and Services Limited",
    "JUBLINDS": "Jubilant Industries Limited",
    "63MOONS": "63 moons technologies limited",
    "CANTABIL": "Cantabil Retail India Limited",
    "RAMCOIND": "Ramco Industries Limited",
    "JYOTISTRUC": "Jyoti Structures Limited",
    "VSSL": "Vardhman Special Steels Limited",
    "HERCULES": "Hercules Hoists Limited",
    "NSIL": "Nalwa Sons Investments Limited",
    "HLVLTD": "HLV LIMITED",
    "SURYODAY": "Suryoday Small Finance Bank Limited",
    "TNPL": "Tamil Nadu Newsprint & Papers Limited",
    "SUBEXLTD": "Subex Limited",
    "RISHABH": "Rishabh Instruments Limited",
    "SERVOTECH": "Servotech Power Systems Limited",
    "BHAGCHEM": "Bhagiradha Chemicals & Industries Limited",
    "ATFL": "Agro Tech Foods Limited",
    "OMAXE": "Omaxe Limited",
    "EVERESTIND": "Everest Industries Limited",
    "PREMEXPLN": "Premier Explosives Limited",
    "GNA": "GNA Axles Limited",
    "GOKULAGRO": "Gokul Agro Resources Limited",
    "TALBROAUTO": "Talbros Automotive Components Limited",
    "DCMSRIND": "DCM Shriram Industries Limited",
    "NELCO": "NELCO Limited",
    "KICL": "Kalyani Investment Company Limited",
    "MOTISONS": "Motisons Jewellers Limited",
    "5PAISA": "5Paisa Capital Limited",
    "KIRIINDUS": "Kiri Industries Limited",
    "CAPITALSFB": "Capital Small Finance Bank Limited",
    "INDRAMEDCO": "Indraprastha Medical Corporation Limited",
    "AEROFLEX": "Aeroflex Industries Limited",
    "SIRCA": "Sirca Paints India Limited",
    "SHANKARA": "Shankara Building Products Limited",
    "FAIRCHEMOR": "Fairchem Organics Limited",
    "BLKASHYAP": "B. L. Kashyap and Sons Limited",
    "TFCILTD": "Tourism Finance Corporation of India Limited",
    "SADHNANIQ": "Sadhana Nitrochem Limited",
    "INDNIPPON": "India Nippon Electricals Limited",
    "GVKPIL": "GVK Power & Infrastructure Limited",
    "RANEHOLDIN": "Rane Holdings Limited",
    "GEOJITFSL": "Geojit Financial Services Limited",
    "DCW": "DCW Limited",
    "SBGLP": "Suratwwala Business Group Limited",
    "BCLIND": "Bcl Industries Limited",
    "SMSPHARMA": "SMS Pharmaceuticals Limited",
    "DPSCLTD": "DPSC Limited",
    "CAMLINFINE": "Camlin Fine Sciences Limited",
    "CONTROLPR": "Control Print Limited",
    "REFEX": "Refex Industries Limited",
    "KRISHANA": "Krishana Phoschem Limited",
    "EKC": "Everest Kanto Cylinder Limited",
    "WHEELS": "Wheels India Limited",
    "JITFINFRA": "JITF Infralogistics Limited",
    "V2RETAIL": "V2 Retail Limited",
    "SALZERELEC": "Salzer Electronics Limited",
    "UNIVCABLES": "Universal Cables Limited",
    "SPAL": "S. P. Apparels Limited",
    "EPACK": "EPACK Durable Limited",
    "SWELECTES": "Swelect Energy Systems Limited",
    "GPTHEALTH": "GPT Healthcare Limited",
    "HITECHGEAR": "The Hi-Tech Gears Limited",
    "INSECTICID": "Insecticides (India) Limited",
    "PENINLAND": "Peninsula Land Limited",
    "SHREDIGCEM": "Shree Digvijay Cement Co.Ltd",
    "SPIC": "Southern Petrochemicals Industries Corporation  Limited",
    "NIITLTD": "NIIT Limited",
    "BIGBLOC": "Bigbloc Construction Limited",
    "ORIANA": "Oriana Power Limited",
    "SPCENET": "Spacenet Enterprises India Limited",
    "SHALPAINTS": "Shalimar Paints Limited",
    "KAMDHENU": "Kamdhenu Limited",
    "STOVEKRAFT": "Stove Kraft Limited",
    "RKSWAMY": "R K Swamy Limited",
    "NAVKARCORP": "Navkar Corporation Limited",
    "BUTTERFLY": "Butterfly Gandhimathi Appliances Limited",
    "DHAMPURSUG": "Dhampur Sugar Mills Limited",
    "NDTV": "New Delhi Television Limited",
    "ARIHANTSUP": "Arihant Superstructures Limited",
    "VASCONEQ": "Vascon Engineers Limited",
    "KUANTUM": "Kuantum Papers Limited",
    "APTECHT": "Aptech Limited",
    "INDIANHUME": "Indian Hume Pipe Company Limited",
    "ROSSELLIND": "Rossell India Limited",
    "AHL": "Abans Holdings Limited",
    "SOLARA": "Solara Active Pharma Sciences Limited",
    "SUMMITSEC": "Summit Securities Limited",
    "ALICON": "Alicon Castalloy Limited",
    "KSOLVES": "Ksolves India Limited",
    "IGPL": "IG Petrochemicals Limited",
    "STEELCAS": "Steelcast Limited",
    "POKARNA": "Pokarna Limited",
    "ATL": "Allcargo Terminals Limited",
    "ATULAUTO": "Atul Auto Limited",
    "GANESHBE": "Ganesh Benzoplast Limited",
    "COSMOFIRST": "COSMO FIRST LIMITED",
    "AWHCL": "Antony Waste Handling Cell Limited",
    "DWARKESH": "Dwarikesh Sugar Industries Limited",
    "HARIOMPIPE": "Hariom Pipe Industries Limited",
    "SMCGLOBAL": "SMC Global Securities Limited",
    "MADRASFERT": "Madras Fertilizers Limited",
    "DSSL": "Dynacons Systems & Solutions Limited",
    "STEELXIND": "STEEL EXCHANGE INDIA LIMITED",
    "MONTECARLO": "Monte Carlo Fashions Limited",
    "E2E": "E2E Networks Limited",
    "VERTOZ": "Vertoz Advertising Limited",
    "GIRIRAJ": "Giriraj Civil Developers Limited",
    "NGLFINE": "NGL Fine-Chem Limited",
    "IGARASHI": "Igarashi Motors India Limited",
    "JAYBARMARU": "Jay Bharat Maruti Limited",
    "AVTNPL": "AVT Natural Products Limited",
    "SKYGOLD": "Sky Gold Limited",
    "TVTODAY": "TV Today Network Limited",
    "XCHANGING": "Xchanging Solutions Limited",
    "ANDHRSUGAR": "The Andhra Sugars Limited",
    "ACLGATI": "Allcargo Gati Limited",
    "KOPRAN": "Kopran Limited",
    "ENIL": "Entertainment Network (India) Limited",
    "VERANDA": "Veranda Learning Solutions Limited",
    "MVGJL": "Manoj Vaibhav Gems N Jewellers Limited",
    "OMINFRAL": "OM INFRA LIMITED",
    "ZOTA": "Zota Health Care LImited",
    "SNOWMAN": "Snowman Logistics Limited",
    "RAJRILTD": "Raj Rayon Industries Limited",
    "PUNJABCHEM": "Punjab Chemicals & Crop Protection Limited",
    "KITEX": "Kitex Garments Limited",
    "TEXINFRA": "Texmaco Infrastructure & Holdings Limited",
    "IMPAL": "India Motor Parts and Accessories Limited",
    "HIMATSEIDE": "Himatsingka Seide Limited",
    "DOLATALGO": "Dolat Algotech Limited",
    "MANGCHEFER": "Mangalore Chemicals & Fertilizers Limited",
    "AGARIND": "Agarwal Industrial Corporation Limited",
    "REPRO": "Repro India Limited",
    "DOLPHIN": "Dolphin Offshore Enterprises (India) Limited",
    "UTTAMSUGAR": "Uttam Sugar Mills Limited",
    "CENTRUM": "Centrum Capital Limited",
    "HESTERBIO": "Hester Biosciences Limited",
    "BETA": "Beta Drugs Limited",
    "MARINE": "Marine Electricals (India) Limited",
    "BLISSGVS": "Bliss GVS Pharma Limited",
    "MSPL": "MSP Steel & Power Limited",
    "LINCOLN": "Lincoln Pharmaceuticals Limited",
    "SAURASHCEM": "Saurashtra Cement Limited",
    "MATRIMONY": "Matrimony.Com Limited",
    "HARDWYN": "Hardwyn India Limited",
    "GMBREW": "GM Breweries Limited",
    "SURAJEST": "Suraj Estate Developers Limited",
    "SICALLOG": "Sical Logistics Limited",
    "TREL": "Transindia Real Estate Limited",
    "PAKKA": "PAKKA LIMITED",
    "HERANBA": "Heranba Industries Limited",
    "RAMRAT": "Ram Ratna Wires Limited",
    "DVL": "Dhunseri Ventures Limited",
    "NACLIND": "NACL Industries Limited",
    "RML": "Rane (Madras) Limited",
    "NELCAST": "Nelcast Limited",
    "KOKUYOCMLN": "Kokuyo Camlin Limited",
    "ALLSEC": "Allsec Technologies Limited",
    "MACPOWER": "Macpower CNC Machines Limited",
    "INNOVANA": "Innovana Thinklabs Limited",
    "ROTO": "Roto Pumps Limited",
    "STERTOOLS": "Sterling Tools Limited",
    "MUKKA": "Mukka Proteins Limited",
    "TIL": "TIL Limited",
    "CSLFINANCE": "CSL Finance Limited",
    "GICHSGFIN": "GIC Housing Finance Limited",
    "SATIA": "Satia Industries Limited",
    "MUFTI": "Credo Brands Marketing Limited",
    "CREST": "Crest Ventures Limited",
    "AVADHSUGAR": "Avadh Sugar & Energy Limited",
    "WINDLAS": "Windlas Biotech Limited",
    "KRYSTAL": "Krystal Integrated Services Limited",
    "YUKEN": "Yuken India Limited",
    "ASIANENE": "Asian Energy Services Limited",
    "SPORTKING": "Sportking India Limited",
    "KOTYARK": "Kotyark Industries Limited",
    "CLSEL": "Chaman Lal Setia Exports Limited",
    "KAMOPAINTS": "Kamdhenu Ventures Limited",
    "HUBTOWN": "Hubtown Limited",
    "VALIANTORG": "Valiant Organics Limited",
    "INDOTECH": "Indo Tech Transformers Limited",
    "COFFEEDAY": "Coffee Day Enterprises Limited",
    "SYNCOMF": "Syncom Formulations (India) Limited",
    "NDRAUTO": "Ndr Auto Components Limited",
    "DHANBANK": "Dhanlaxmi Bank Limited",
    "ONEPOINT": "One Point One Solutions Limited",
    "HIRECT": "Hind Rectifiers Limited",
    "KABRAEXTRU": "Kabra Extrusion Technik Limited",
    "REMUS": "Remus Pharmaceuticals Limited",
    "INDORAMA": "Indo Rama Synthetics (India) Limited",
    "GULPOLY": "Gulshan Polyols Limited",
    "HEUBACHIND": "Heubach Colorants India Limited",
    "OAL": "Oriental Aromatics Limited",
    "CREATIVE": "Creative Newtech Limited",
    "ONWARDTEC": "Onward Technologies Limited",
    "URJA": "Urja Global Limited",
    "PVP": "PVP Ventures Limited",
    "ROHLTD": "Royal Orchid Hotels Limited",
    "BLAL": "BEML Land Assets Limited",
    "SATINDLTD": "Sat Industries Limited",
    "VIMTALABS": "Vimta Labs Limited",
    "ZUARIIND": "ZUARI INDUSTRIES LIMITED",
    "GSLSU": "Global Surfaces Limited",
    "NAHARSPING": "Nahar Spinning Mills Limited",
    "MANALIPETC": "Manali Petrochemicals Limited",
    "SASTASUNDR": "Sastasundar Ventures Limited",
    "DLINKINDIA": "D-Link (India) Limited",
    "RGL": "Renaissance Global Limited",
    "FOCUS": "Focus Lighting and Fixtures Limited",
    "GANDHITUBE": "Gandhi Special Tubes Limited",
    "KELLTONTEC": "Kellton Tech Solutions Limited",
    "PLATIND": "Platinum Industries Limited",
    "KERNEX": "Kernex Microsystems (India) Limited",
    "RAMCOSYS": "Ramco Systems Limited",
    "WALCHANNAG": "Walchandnagar Industries Limited",
    "CHEMFAB": "Chemfab Alkalis Limited",
    "UNIENTER": "Uniphos Enterprises Limited",
    "SARVESHWAR": "Sarveshwar Foods Limited",
    "BODALCHEM": "Bodal Chemicals Limited",
    "SEMAC": "SEMAC CONSULTANTS LIMITED",
    "VISAKAIND": "Visaka Industries Limited",
    "LIKHITHA": "Likhitha Infrastructure Limited",
    "WEL": "Wonder Electricals Limited",
    "ASAL": "Automotive Stampings and Assemblies Limited",
    "AMNPLST": "Amines & Plasticizers Limited",
    "GPTINFRA": "GPT Infraprojects Limited",
    "NINSYS": "NINtec Systems Limited",
    "SHIVALIK": "Shivalik Rasayan Limited",
    "VHL": "Vardhman Holdings Limited",
    "OWAIS": "Owais Metal And Mineral Processing Limited",
    "TRACXN": "Tracxn Technologies Limited",
    "EXCELINDUS": "Excel Industries Limited",
    "INFOBEAN": "InfoBeans Technologies Limited",
    "SANDESH": "The Sandesh Limited",
    "EIMCOELECO": "Eimco Elecon (India) Limited",
    "CENTENKA": "Century Enka Limited",
    "HPAL": "HP Adhesives Limited",
    "MICEL": "MIC Electronics Limited",
    "FAZE3Q": "Faze Three Limited",
    "ORIENTPPR": "Orient Paper & Industries Limited",
    "MAXIND": "Max India Limited",
    "GRPLTD": "GRP Limited",
    "DENORA": "De Nora India Limited",
    "ASALCBR": "Associated Alcohols & Breweries Ltd.",
    "GKWLIMITED": "GKW Limited",
    "VLSFINANCE": "VLS Finance Limited",
    "SPECIALITY": "Speciality Restaurants Limited",
    "CHEMCON": "Chemcon Speciality Chemicals Limited",
    "SRHHYPOLTD": "Sree Rayalaseema Hi-Strength Hypo Limited",
    "PPL": "Prakash Pipes Limited",
    "NCLIND": "NCL Industries Limited",
    "HEXATRADEX": "Hexa Tradex Limited",
    "DECCANCE": "Deccan Cements Limited",
    "SUTLEJTEX": "Sutlej Textiles and Industries Limited",
    "SPENCERS": "Spencer's Retail Limited",
    "BASILIC": "Basilic Fly Studio Limited",
    "SILVERTUC": "Silver Touch Technologies Limited",
    "SCHAND": "S Chand And Company Limited",
    "AGSTRA": "AGS Transact Technologies Limited",
    "DYCL": "Dynamic Cables Limited",
    "ALPEXSOLAR": "Alpex Solar Limited",
    "RADIANTCMS": "Radiant Cash Management Services Limited",
    "AMBIKCO": "Ambika Cotton Mills Limited",
    "BAJAJHCARE": "Bajaj Healthcare Limited",
    "RSWM": "RSWM Limited",
    "SAKAR": "Sakar Healthcare Limited",
    "MUNJALAU": "Munjal Auto Industries Limited",
    "HMVL": "Hindustan Media Ventures Limited",
    "ELDEHSG": "Eldeco Housing And Industries Limited",
    "INDOAMIN": "Indo Amines Limited",
    "DENTALKART": "Vasa Denticity Limited",
    "VIKASLIFE": "Vikas Lifecare Limited",
    "RUSHIL": "Rushil Decor Limited",
    "ADSL": "Allied Digital Services Limited",
    "BCONCEPTS": "Brand Concepts Limited",
    "DBOL": "Dhampur Bio Organics Limited",
    "LINC": "Linc Limited",
    "RADHIKAJWE": "Radhika Jeweltech Limited",
    "DHARMAJ": "Dharmaj Crop Guard Limited",
    "MAGADSUGAR": "Magadh Sugar & Energy Limited",
    "CHEVIOT": "Cheviot Company Limited",
    "VINYAS": "Vinyas Innovative Technologies Limited",
    "BALAJITELE": "Balaji Telefilms Limited",
    "OSWALGREEN": "Oswal Greentech Limited",
    "ICEMAKE": "Ice Make Refrigeration Limited",
    "GFLLIMITED": "GFL Limited",
    "PANACEABIO": "Panacea Biotec Limited",
    "STCINDIA": "The State Trading Corporation of India Limited",
    "TRU": "TruCap Finance Limited",
    "UGARSUGAR": "The Ugar Sugar Works Limited",
    "MAANALU": "Maan Aluminium Limited",
    "NRAIL": "N R Agarwal Industries Limited",
    "JAGSNPHARM": "Jagsonpal Pharmaceuticals Limited",
    "GHCLTEXTIL": "GHCL Textiles Limited",
    "ASIANTILES": "Asian Granito India Limited",
    "DAVANGERE": "Davangere Sugar Company Limited",
    "POCL": "Pondy Oxides & Chemicals Limited",
    "KOTHARIPET": "Kothari Petrochemicals Limited",
    "CONSOFINVT": "Consolidated Finvest & Holdings Limited",
    "ACL": "Andhra Cements Limited",
    "ZUARI": "Zuari Agro Chemicals Limited",
    "GRMOVER": "GRM Overseas Limited",
    "BEDMUTHA": "Bedmutha Industries Limited",
    "SUKHJITS": "Sukhjit Starch & Chemicals Limited",
    "ESTER": "Ester Industries Limited",
    "WSI": "W S Industries (I) Limited",
    "TNPETRO": "Tamilnadu PetroProducts Limited",
    "FOODSIN": "Foods & Inns Limited",
    "THEINVEST": "The Investment Trust Of India Limited",
    "DHUNINV": "Dhunseri Investments Limited",
    "TBZ": "Tribhovandas Bhimji Zaveri Limited",
    "EMKAYTOOLS": "Emkay Taps and Cutting Tools Limited",
    "KECL": "Kirloskar Electric Company Limited",
    "EMAMIPAP": "Emami Paper Mills Limited",
    "ELECTHERM": "Electrotherm (India) Limited",
    "LOKESHMACH": "Lokesh Machines Limited",
    "SELAN": "Selan Exploration Technology Limited",
    "AVG": "AVG Logistics Limited",
    "SAHANA": "Sahana System Limited",
    "DMCC": "DMCC SPECIALITY CHEMICALS LIMITED",
    "NECLIFE": "Nectar Lifesciences Limited",
    "BIRLACABLE": "Birla Cable Limited",
    "GOACARBON": "Goa Carbon Limited",
    "JGCHEM": "J.G.Chemicals Limited",
    "ANNAPURNA": "Annapurna Swadisht Limited",
    "3IINFOLTD": "3i Infotech Limited",
    "WEALTH": "Wealth First Portfolio Managers Limited",
    "PARSVNATH": "Parsvnath Developers Limited",
    "ADVANIHOTR": "Advani Hotels & Resorts (India) Limited",
    "KRITI": "Kriti Industries (India) Limited",
    "ELIN": "Elin Electronics Limited",
    "DPWIRES": "D P Wires Limited",
    "MEGASOFT": "Megasoft Limited",
    "OCCL": "Oriental Carbon & Chemicals Limited",
    "MUNJALSHOW": "Munjal Showa Limited",
    "ZEEMEDIA": "Zee Media Corporation Limited",
    "SOLEX": "Solex Energy Limited",
    "MMP": "MMP Industries Limited",
    "CHEMBOND": "Chembond Chemicals Ltd",
    "JAYAGROGN": "Jayant Agro Organics Limited",
    "JPOLYINVST": "Jindal Poly Investment and Finance Company Limited",
    "MANAKSIA": "Manaksia Limited",
    "SREEL": "Sreeleathers Limited",
    "ONMOBILE": "OnMobile Global Limited",
    "SBC": "SBC Exports Limited",
    "SPMLINFRA": "SPML Infra Limited",
    "BHAGERIA": "Bhageria Industries Limited",
    "VALIANTLAB": "Valiant Laboratories Limited",
    "MENONBE": "Menon Bearings Limited",
    "KILITCH": "Kilitch Drugs (India) Limited",
    "PAVNAIND": "Pavna Industries Limited",
    "KHAICHEM": "Khaitan Chemicals & Fertilizers Limited",
    "FCSSOFT": "FCS Software Solutions Limited",
    "MALLCOM": "Mallcom (India) Limited",
    "NRL": "Nupur Recyclers Limited",
    "PHANTOMFX": "Phantom Digital Effects Limited",
    "APEX": "Apex Frozen Foods Limited",
    "KAMATHOTEL": "Kamat Hotels (I) Limited",
    "HTMEDIA": "HT Media Limited",
    "RUBYMILLS": "The Ruby Mills Limited",
    "ALBERTDAVD": "Albert David Limited",
    "KODYTECH": "Kody Technolab Limited",
    "LGHL": "Laxmi Goldorna House Limited",
    "PRIMESECU": "Prime Securities Limited",
    "RBZJEWEL": "RBZ Jewellers Limited",
    "PLASTIBLEN": "Plastiblends India Limited",
    "IRISDOREME": "Iris Clothings Limited",
    "PDMJEPAPER": "Pudumjee Paper Products Limited",
    "INDSWFTLAB": "Ind-Swift Laboratories Limited",
    "MIRZAINT": "Mirza International Limited",
    "STEL": "Stel Holdings Limited",
    "SAKUMA": "Sakuma Exports Limited",
    "SIMPLEXINF": "Simplex Infrastructures Limited",
    "ARROWGREEN": "Arrow Greentech Limited",
    "VINYLINDIA": "Vinyl Chemicals (India) Limited",
    "OSWALAGRO": "Oswal Agro Mills Limited",
    "HINDCOMPOS": "Hindustan Composites Limited",
    "ARIHANTCAP": "Arihant Capital Markets Limited",
    "MBLINFRA": "MBL Infrastructure Limited",
    "DEEPENR": "DEEP ENERGY RESOURCES LIMITED",
    "ORICONENT": "Oricon Enterprises Limited",
    "SHREYAS": "Shreyas Shipping & Logistics Limited",
    "SKMEGGPROD": "SKM Egg Products Export (India) Limited",
    "ORIENTCER": "ORIENT CERATECH LIMITED",
    "DIGISPICE": "DiGiSPICE Technologies Limited",
    "ZODIAC": "Zodiac Energy Limited",
    "KRISHIVAL": "Krishival Foods Limited",
    "JETAIRWAYS": "Jet Airways (India) Limited",
    "RATNAVEER": "Ratnaveer Precision Engineering Limited",
    "JINDALPHOT": "Jindal Photo Limited",
    "RADIOCITY": "Music Broadcast Limited",
    "HCL-INSYS": "HCL Infosystems Limited",
    "RBL": "Rane Brake Lining Limited",
    "KHADIM": "Khadim India Limited",
    "AXITA": "Axita Cotton Limited",
    "ASMS": "Bartronics India Limited",
    "BIRLAMONEY": "Aditya Birla Money Limited",
    "VISHNUINFR": "Vishnusurya Projects and Infra Limited",
    "VLEGOV": "VL E-Governance & IT Solutions Limited",
    "BBTCL": "B&B Triplewall Containers Limited",
    "NAGAFERT": "Nagarjuna Fertilizers and Chemicals Limited",
    "GEECEE": "GeeCee Ventures Limited",
    "RACE": "Race Eco Chain Limited",
    "RITCO": "Ritco Logistics Limited",
    "TCLCONS": "Tantia Constructions Limited",
    "BALAXI": "BALAXI PHARMACEUTICALS LIMITED",
    "PYRAMID": "Pyramid Technoplast Limited",
    "REMSONSIND": "Remsons Industries Limited",
    "UFO": "UFO Moviez India Limited",
    "ACCENTMIC": "Accent Microcell Limited",
    "PTL": "PTL Enterprises Limited",
    "INDOBORAX": "Indo Borax & Chemicals Limited",
    "MOLDTECH": "Mold-Tek Technologies Limited",
    "MAZDA": "Mazda Limited",
    "MINDTECK": "Mindteck (India) Limited",
    "COOLCAPS": "Cool Caps Industries Limited",
    "ALLETEC": "All E Technologies Limited",
    "20MICRONS": "20 Microns Limited",
    "VIKASECO": "Vikas EcoTech Limited",
    "ORIENTBELL": "Orient Bell Limited",
    "DONEAR": "Donear Industries Limited",
    "CAREERP": "Career Point Limited",
    "INTLCONV": "International Conveyors Limited",
    "SHREEPUSHK": "Shree Pushkar Chemicals & Fertilisers Limited",
    "PRITIKAUTO": "Pritika Auto Industries Limited",
    "DIAMINESQ": "Diamines & Chemicals Limited",
    "SILINV": "SIL Investments Limited",
    "BANSWRAS": "Banswara Syntex Limited",
    "SADBHAV": "Sadbhav Engineering Limited",
    "APCL": "Anjani Portland Cement Limited",
    "NAHARINDUS": "Nahar Industrial Enterprises Limited",
    "DUGLOBAL": "DUDIGITAL GLOBAL LIMITED",
    "GEEKAYWIRE": "Geekay Wires Limited",
    "SGIL": "Synergy Green Industries Limited",
    "TIRUPATI": "Shree Tirupati Balajee FIBC Limited",
    "MEDICAMEQ": "Medicamen Biotech Limited",
    "MEGATHERM": "Megatherm Induction Limited",
    "TPLPLASTEH": "TPL Plastech Limited",
    "RSSOFTWARE": "R. S. Software (India) Limited",
    "RBMINFRA": "Rbm Infracon Limited",
    "WANBURY": "Wanbury Limited",
    "GENUSPAPER": "Genus Paper & Boards Limited",
    "KANORICHEM": "Kanoria Chemicals & Industries Limited",
    "VSTL": "Vibhor Steel Tubes Limited",
    "LIBERTSHOE": "Liberty Shoes Limited",
    "GLOBAL": "Global Education Limited",
    "BRNL": "Bharat Road Network Limited",
    "NOVAAGRI": "Nova Agritech Limited",
    "TVSELECT": "TVS Electronics Limited",
    "VINSYS": "Vinsys IT Services India Limited",
    "NDL": "Nandan Denim Limited",
    "KRITINUT": "Kriti Nutrients Limited",
    "NAHARCAP": "Nahar Capital and Financial Services Limited",
    "CLEDUCATE": "CL Educate Limited",
    "ZIMLAB": "Zim Laboratories Limited",
    "SHIVAMAUTO": "Shivam Autotech Limited",
    "SYSTANGO": "Systango Technologies Limited",
    "UNIDT": "United Drilling Tools Limited",
    "SARLAPOLY": "Sarla Performance Fibers Limited",
    "WTICAB": "Wise Travel India Limited",
    "KRITIKA": "Kritika Wires Limited",
    "TRF": "TRF Limited",
    "AUTOIND": "Autoline Industries Limited",
    "MUTHOOTCAP": "Muthoot Capital Services Limited",
    "PURVFLEXI": "Purv Flexipack Limited",
    "NBIFIN": "N. B. I. Industrial Finance Company Limited",
    "AYMSYNTEX": "AYM Syntex Limited",
    "MIRCELECTR": "MIRC Electronics Limited",
    "NAHARPOLY": "Nahar Poly Films Limited",
    "IITL": "Industrial Investment Trust Limited",
    "GOLDTECH": "AION-TECH SOLUTIONS LIMITED",
    "VENUSREM": "Venus Remedies Limited",
    "KRISHNADEF": "Krishna Defence and Allied Industries Limited",
    "WINDMACHIN": "Windsor Machines Limited",
    "APS": "Australian Premium Solar (India) Limited",
    "APOLSINHOT": "Apollo Sindoori Hotels Limited",
    "BPL": "BPL Limited",
    "CYBERTECH": "Cybertech Systems And Software Limited",
    "KOTARISUG": "Kothari Sugars And Chemicals Limited",
    "IL&FSENGG": "IL&FS Engineering and Construction Company Limited",
    "SHIVAUM": "Shiv Aum Steels Limited",
    "ESSARSHPNG": "Essar Shipping Limited",
    "CELLECOR": "Cellecor Gadgets Limited",
    "PROZONER": "Prozone Realty Limited",
    "AARTISURF": "Aarti Surfactants Limited",
    "LAL": "Lorenzini Apparels Limited",
    "CINELINE": "Cineline India Limited",
    "TECHLABS": "Trident Techlabs Limited",
    "FELIX": "Felix Industries Limited",
    "NIPPOBATRY": "Indo-National Limited",
    "PREMIERPOL": "Premier Polyfilm Limited",
    "VARDHACRLC": "Vardhman Acrylics Limited",
    "RPPINFRA": "R.P.P. Infra Projects Limited",
    "HIGREEN": "Hi-Green Carbon Limited",
    "SURANI": "Surani Steel Tubes Limited",
    "NILAINFRA": "Nila Infrastructures Limited",
    "KAYA": "Kaya Limited",
    "SJLOGISTIC": "S J Logistics (India) Limited",
    "CRAYONS": "Crayons Advertising Limited",
    "NITCO": "Nitco Limited",
    "EUROBOND": "Euro Panel Products Limited",
    "MEDICO": "Medico Remedies Limited",
    "ALANKIT": "Alankit Limited",
    "EMAMIREAL": "Emami Realty Limited",
    "SAAKSHI": "Saakshi Medtech and Panels Limited",
    "SEJALLTD": "Sejal Glass Limited",
    "SHEMAROO": "Shemaroo Entertainment Limited",
    "BEWLTD": "BEW Engineering Limited",
    "IFBAGRO": "IFB Agro Industries Limited",
    "DICIND": "DIC India Limited",
    "PRECOT": "Precot Limited",
    "ASAHISONG": "Asahi Songwon Colors Limited",
    "ORBTEXP": "Orbit Exports Limited",
    "MODISONLTD": "MODISON LIMITED",
    "AURUM": "Aurum PropTech Limited",
    "KDL": "Kore Digital Limited",
    "EXXARO": "Exxaro Tiles Limited",
    "KARNIKA": "Karnika Industries Limited",
    "SAHYADRI": "Sahyadri Industries Limited",
    "SKP": "SKP Bearing Industries Limited",
    "KCPSUGIND": "KCP Sugar and Industries Corporation Limited",
    "MKPL": "M K Proteins Limited",
    "MANAKSTEEL": "Manaksia Steels Limited",
    "UNIVPHOTO": "Universus Photo Imagings Limited",
    "SCPL": "Sheetal Cool Products Limited",
    "SIGMA": "Sigma Solve Limited",
    "IZMO": "IZMO Limited",
    "SINTERCOM": "Sintercom India Limited",
    "FOCE": "Foce India Limited",
    "PODDARMENT": "Poddar Pigments Limited",
    "PLAZACABLE": "Plaza Wires Limited",
    "DCMNVL": "DCM Nouvelle Limited",
    "SHYAMCENT": "Shyam Century Ferrous Limited",
    "SAKHTISUG": "Sakthi Sugars Limited",
    "ESFL": "Essen Speciality Films Limited",
    "ATMASTCO": "Atmastco Limited",
    "KORE": "Jay Jalaram Technologies Limited",
    "LYKALABS": "Lyka Labs Limited",
    "STARPAPER": "Star Paper Mills Limited",
    "SRGHFL": "SRG Housing Finance Limited",
    "HINDMOTORS": "Hindustan Motors Limited",
    "PONNIERODE": "Ponni Sugars (Erode) Limited",
    "ASHIMASYN": "Ashima Limited",
    "KOTHARIPRO": "Kothari Products Limited",
    "GOKUL": "Gokul Refoils and Solvent Limited",
    "HITECHCORP": "Hitech Corporation Limited",
    "DIGIKORE": "Digikore Studios Limited",
    "KNAGRI": "KN Agri Resources Limited",
    "URAVI": "Uravi T and Wedge Lamps Limited",
    "LAWSIKHO": "Addictive Learning Technology Limited",
    "GOLDSTAR": "Goldstar Power Limited",
    "TCL": "Thaai Casting Limited",
    "RUCHIRA": "Ruchira Papers Limited",
    "MAHEPC": "Mahindra EPC Irrigation Limited",
    "PROV": "Proventus Agrocom Limited",
    "OSIAHYPER": "Osia Hyper Retail Limited",
    "MAWANASUG": "Mawana Sugars Limited",
    "RHL": "Robust Hotels Limited",
    "NATHBIOGEN": "Nath Bio-Genes (India) Limited",
    "EIFFL": "Euro India Fresh Foods Limited",
    "SARTELE": "Sar Televenture Limited",
    "VIPULLTD": "Vipul Limited",
    "ESSENTIA": "Integra Essentia Limited",
    "MWL": "Mangalam Worldwide Limited",
    "UCAL": "UCAL LIMITED",
    "PASUPTAC": "Pasupati Acrylon Limited",
    "DRONE": "Drone Destination Limited",
    "INFINIUM": "Infinium Pharmachem Limited",
    "COASTCORP": "Coastal Corporation Limited",
    "MOS": "Mos Utility Limited",
    "BIL": "Bhartiya International Limited",
    "INDTERRAIN": "Indian Terrain Fashions Limited",
    "ARIES": "Aries Agro Limited",
    "SHREERAMA": "Shree Rama Multi-Tech Limited",
    "DYNPRO": "Dynemic Products Limited",
    "SHERA": "Shera Energy Limited",
    "MHLXMIRU": "Mahalaxmi Rubtech Limited",
    "NDLVENTURE": "NDL Ventures Limited",
    "GULFPETRO": "GP Petroleums Limited",
    "TAKE": "Take Solutions Limited",
    "EQUIPPP": "Equippp Social Impact Technologies Limited",
    "SMLT": "Sarthak Metals Limited",
    "HARRMALAYA": "Harrisons  Malayalam Limited",
    "UMAEXPORTS": "Uma Exports Limited",
    "LANCORHOL": "Lancor Holdings Limited",
    "PROPEQUITY": "P. E. Analytics Limited",
    "SOFTTECH": "Softtech Engineers Limited",
    "MANOMAY": "Manomay Tex India Limited",
    "REPL": "Rudrabhishek Enterprises Limited",
    "RANASUG": "Rana Sugars Limited",
    "NEWJAISA": "Newjaisa Technologies Limited",
    "SHREYANIND": "Shreyans Industries Limited",
    "IVC": "IL&FS Investment Managers Limited",
    "RAJTV": "Raj Television Network Limited",
    "VIPCLOTHNG": "VIP Clothing Limited",
    "ABINFRA": "A B Infrabuild Limited",
    "TIPSFILMS": "Tips Films Limited",
    "SOUTHWEST": "South West Pinnacle Exploration Limited",
    "ABAN": "Aban Offshore Limited",
    "AIRAN": "Airan Limited",
    "SUPREMEPWR": "Supreme Power Equipment Limited",
    "VIRINCHI": "Virinchi Limited",
    "GOYALSALT": "Goyal Salt Limited",
    "TRIGYN": "Trigyn Technologies Limited",
    "EMKAY": "Emkay Global Financial Services Limited",
    "INDOTHAI": "Indo Thai Securities Limited",
    "RAJMET": "Rajnandini Metal Limited",
    "RAMAPHO": "Rama Phosphates Limited",
    "MGEL": "Mangalam Global Enterprise Limited",
    "LORDSCHLO": "Lords Chloro Alkali Limited",
    "SWARAJ": "Swaraj Suiting Limited",
    "RPPL": "Rajshree Polypack Limited",
    "ALMONDZ": "Almondz Global Securities Limited",
    "TEMBO": "Tembo Global Industries Limited",
    "ZODIACLOTH": "Zodiac Clothing Company Limited",
    "ASCOM": "Ascom Leasing & Investments Limited",
    "PILITA": "PIL ITALICA LIFESTYLE LIMITED",
    "AARON": "Aaron Industries Limited",
    "JAYSREETEA": "Jayshree Tea & Industries Limited",
    "KRISHCA": "Krishca Strapping Solutions Limited",
    "INTENTECH": "Intense Technologies Limited",
    "RUCHINFRA": "Ruchi Infrastructure Limited",
    "MURUDCERA": "Murudeshwar Ceramics Limited",
    "DRCSYSTEMS": "DRC Systems India Limited",
    "SUNDRMBRAK": "Sundaram Brake Linings Limited",
    "VISHWARAJ": "Vishwaraj Sugar Industries Limited",
    "USK": "Udayshivakumar Infra Limited",
    "HINDCON": "Hindcon Chemicals Limited",
    "OSWALSEEDS": "ShreeOswal Seeds And Chemicals Limited",
    "PAR": "Par Drugs And Chemicals Limited",
    "MEGASTAR": "Megastar Foods Limited",
    "ZEAL": "Zeal Global Services Limited",
    "BHAGYANGR": "Bhagyanagar India Limited",
    "KONSTELEC": "Konstelec Engineers Limited",
    "WELINV": "Welspun Investments and Commercials Limited",
    "MARALOVER": "Maral Overseas Limited",
    "MAGNUM": "Magnum Ventures Limited",
    "GINNIFILA": "Ginni Filaments Limited",
    "SADHAV": "Sadhav Shipping Limited",
    "TARACHAND": "Tara Chand InfraLogistic Solutions Limited",
    "KMSUGAR": "K.M.Sugar Mills Limited",
    "ASIANHOTNR": "Asian Hotels (North) Limited",
    "SVLL": "Shree Vasu Logistics Limited",
    "GUJAPOLLO": "Gujarat Apollo Industries Limited",
    "NURECA": "Nureca Limited",
    "STARTECK": "Starteck Finance Limited",
    "FROG": "Frog Cellsat Limited",
    "PRAXIS": "Praxis Home Retail Limited",
    "SHREEKARNI": "Shree Karni Fabcom Limited",
    "COMSYN": "Commercial Syn Bags Limited",
    "ABCOTS": "A B Cotspin India Limited",
    "BGRENERGY": "BGR Energy Systems Limited",
    "RKEC": "RKEC Projects Limited",
    "MCLEODRUSS": "Mcleod Russel India Limited",
    "CHAVDA": "Chavda Infra Limited",
    "LOYALTEX": "Loyal Textile Mills Limited",
    "KAPSTON": "Kapston Services Limited",
    "BROOKS": "Brooks Laboratories Limited",
    "THOMASCOTT": "Thomas Scott (India) Limited",
    "BASML": "Bannari Amman Spinning Mills Limited",
    "RVHL": "Ravinder Heights Limited",
    "PPAP": "PPAP Automotive Limited",
    "DELPHIFX": "DELPHI WORLD MONEY LIMITED",
    "ELGIRUBCO": "Elgi Rubber Company Limited",
    "SADBHIN": "Sadbhav Infrastructure Project Limited",
    "DCI": "Dc Infotech And Communication Limited",
    "ANMOL": "Anmol India Limited",
    "A2ZINFRA": "A2Z Infra Engineering Limited",
    "AHLEAST": "Asian Hotels (East) Limited",
    "VETO": "Veto Switchgears And Cables Limited",
    "MANORG": "Mangalam Organics Limited",
    "SAH": "Sah Polymers Limited",
    "ROXHITECH": "Rox Hi Tech Limited",
    "AIROLAM": "Airo Lam limited",
    "BAIDFIN": "Baid Finserv Limited",
    "SELMC": "SEL Manufacturing Company Limited",
    "DRSDILIP": "DRS Dilip Roadlines Limited",
    "TEXMOPIPES": "Texmo Pipes and Products Limited",
    "TTL": "T T Limited",
    "GIRRESORTS": "GIR Natureview Resorts Limited",
    "MANAKCOAT": "Manaksia Coated Metals & Industries Limited",
    "JMA": "Jullundur Motor Agency (Delhi) Limited",
    "CORDSCABLE": "Cords Cable Industries Limited",
    "DEVIT": "Dev Information Technology Limited",
    "IRIS": "Iris Business Services Limited",
    "CROWN": "Crown Lifters Limited",
    "RANEENGINE": "Rane Engine Valve Limited",
    "OMAXAUTO": "Omax Autos Limited",
    "TREJHARA": "TREJHARA SOLUTIONS LIMITED",
    "NILASPACES": "Nila Spaces Limited",
    "BYKE": "The Byke Hospitality Ltd",
    "MODIRUBBER": "Modi Rubber Limited",
    "ANLON": "Anlon Technology Solutions Limited",
    "PRITI": "Priti International Limited",
    "PARAGON": "Paragon Fine and Speciality Chemical Limited",
    "NECCLTD": "North Eastern Carrying Corporation Limited",
    "CCHHL": "Country Club Hospitality & Holidays Limited",
    "SUPERHOUSE": "Superhouse Limited",
    "RAMANEWS": "Shree Rama Newsprint Limited",
    "HILTON": "Hilton Metal Forging Limited",
    "NDGL": "Naga Dhunseri Group Limited",
    "GSS": "GSS Infotech Limited",
    "LGBFORGE": "LGB Forge Limited",
    "INDOWIND": "Indowind Energy Limited",
    "MEP": "MEP Infrastructure Developers Limited",
    "BTML": "Bodhi Tree Multimedia Limited",
    "VMARCIND": "V Marc India Limited",
    "UNITEDPOLY": "United Polyfab Gujarat Limited",
    "RMDRIP": "R M Drip and Sprinklers Systems Limited",
    "ALPHAGEO": "Alphageo (India) Limited",
    "QMSMEDI": "QMS Medical Allied Services Limited",
    "USASEEDS": "Upsurge Seeds Of Agriculture Limited",
    "UNIHEALTH": "Unihealth Consultancy Limited",
    "ROCKINGDCE": "Rockingdeals Circular Economy Limited",
    "ESCONET": "Esconet Technologies Limited",
    "SMSLIFE": "SMS Lifesciences India Limited",
    "VARDMNPOLY": "Vardhman Polytex Limited",
    "AVONMORE": "Avonmore Capital & Management Services Limited",
    "LAGNAM": "Lagnam Spintex Limited",
    "AKSHARCHEM": "AksharChem India Limited",
    "MODTHREAD": "Modern Threads (India) Limited",
    "SSFL": "Srivari Spices And Foods Limited",
    "IEL": "Indiabulls Enterprises Limited",
    "BAHETI": "Baheti Recycling Industries Limited",
    "ZEELEARN": "Zee Learn Limited",
    "GENCON": "Generic Engineering Construction and Projects Limited",
    "SURANAT&P": "Surana Telecom and Power Limited",
    "DELAPLEX": "Delaplex Limited",
    "ASPINWALL": "Aspinwall and Company Limited",
    "DTIL": "Dhunseri Tea & Industries Limited",
    "DENEERS": "De Neers Tools Limited",
    "KCEIL": "Kay Cee Energy & Infra Limited",
    "AVPINFRA": "AVP Infracon Limited",
    "KANPRPLA": "Kanpur Plastipack Limited",
    "CMNL": "Chaman Metallics Limited",
    "RAJSREESUG": "Rajshree Sugars & Chemicals Limited",
    "KBCGLOBAL": "KBC Global Limited",
    "EFORCE": "Electro Force (India) Limited",
    "AURDIS": "Aurangabad Distillery Limited",
    "INDBANK": "Indbank Merchant Banking Services Limited",
    "TARMAT": "Tarmat Limited",
    "INM": "Interiors & More Limited",
    "MAXPOSURE": "Maxposure Limited",
    "INFOLLION": "Infollion Research Services Limited",
    "BAFNAPH": "Bafna Pharmaceuticals Limited",
    "MAHAPEXLTD": "Maha Rashtra Apex Corporation Limited",
    "EFACTOR": "E Factor Experiences Limited",
    "CLOUD": "Varanium Cloud Limited",
    "COMPUSOFT": "Compucom Software Limited",
    "DJML": "DJ Mediaprint & Logistics Limited",
    "DUCON": "Ducon Infratechnologies Limited",
    "ATLANTAA": "ATLANTAA LIMITED",
    "PRATHAM": "Pratham EPC Projects Limited",
    "MADHAVBAUG": "Vaidya Sane Ayurved Laboratories Limited",
    "MAHESHWARI": "Maheshwari Logistics Limited",
    "SHIVATEX": "Shiva Texyarn Limited",
    "SIGIND": "Signet Industries Limited",
    "DHRUV": "Dhruv Consultancy Services Limited",
    "SECL": "Salasar Exteriors and Contour Limited",
    "NITIRAJ": "Nitiraj Engineers Limited",
    "INVENTURE": "Inventure Growth & Securities Limited",
    "ATAM": "Atam Valves Limited",
    "SPECTSTM": "Spectrum Talent Management Limited",
    "AARVI": "Aarvi Encon Limited",
    "WEIZMANIND": "Weizmann Limited",
    "SALSTEEL": "S.A.L. Steel Limited",
    "ALPA": "Alpa Laboratories Limited",
    "GVPTECH": "GVP Infotech Limited",
    "SHREEOSFM": "Shree OSFM E-Mobility Limited",
    "SONAMAC": "Sona Machinery Limited",
    "GLOBALVECT": "Global Vectra Helicorp Limited",
    "MANAKALUCO": "Manaksia Aluminium Company Limited",
    "INCREDIBLE": "INCREDIBLE INDUSTRIES LIMITED",
    "BLBLIMITED": "BLB Limited",
    "EROSMEDIA": "Eros International Media Limited",
    "SUPREMEINF": "Supreme Infrastructure India Limited",
    "SMARTLINK": "Smartlink Holdings Limited",
    "VITAL": "Vital Chemtech Limited",
    "VISESHINFO": "Visesh Infotecnics Limited",
    "CADSYS": "Cadsys (India) Limited",
    "XELPMOC": "Xelpmoc Design And Tech Limited",
    "SWASTIK": "Swastik Pipe Limited",
    "PRAENG": "Prajay Engineers Syndicate Limited",
    "MUKTAARTS": "Mukta Arts Limited",
    "PARIN": "Parin Furniture Limited",
    "VIVIANA": "Viviana Power Tech Limited",
    "UMANGDAIRY": "Umang Dairies Limited",
    "BSL": "BSL Limited",
    "MAHASTEEL": "Mahamaya Steel Industries Limited",
    "ARCHIDPLY": "Archidply Industries Limited",
    "CTE": "Cambridge Technology Enterprises Limited",
    "ARSHIYA": "Arshiya Limited",
    "AARTECH": "Aartech Solonics Limited",
    "SALONA": "Salona Cotspin Limited",
    "LOVABLE": "Lovable Lingerie Limited",
    "CANARYS": "Canarys Automations Limited",
    "BAWEJA": "Baweja Studios Limited",
    "URBAN": "Urban Enviro Waste Management Limited",
    "BAGFILMS": "B.A.G Films and Media Limited",
    "ISFT": "Intrasoft Technologies Limited",
    "KLL": "Kaushalya Logistics Limited",
    "GREENCHEF": "Greenchef Appliances Limited",
    "IVP": "IVP Limited",
    "WORTH": "Worth Peripherals Limited",
    "SONAMLTD": "SONAM LIMITED",
    "SUMIT": "Sumit Woods Limited",
    "TIRUPATIFL": "Tirupati Forge Limited",
    "EMMBI": "Emmbi Industries Limited",
    "ARVEE": "Arvee Laboratories (India) Limited",
    "UNITEDTEA": "The United Nilgiri Tea Estates Company Limited",
    "UNIVASTU": "Univastu India Limited",
    "LLOYDS": "Lloyds Luxuries Limited",
    "SURANASOL": "Surana Solar Limited",
    "SOMICONVEY": "Somi Conveyor Beltings Limited",
    "KAKATCEM": "Kakatiya Cement Sugar & Industries Limited",
    "SHIGAN": "Shigan Quantum Technologies Limited",
    "BHARATGEAR": "Bharat Gears Limited",
    "ONDOOR": "On Door Concepts Limited",
    "OILCOUNTUB": "Oil Country Tubular Limited",
    "SPLIL": "SPL Industries Limited",
    "CAPTRUST": "Capital Trust Limited",
    "FIDEL": "Fidel Softech Limited",
    "MDL": "Marvel Decor Limited",
    "3RDROCK": "3rd Rock Multimedia Limited",
    "VEEKAYEM": "Veekayem Fashion and Apparels Limited",
    "DYNAMIC": "Dynamic Services & Security Limited",
    "GILLANDERS": "Gillanders Arbuthnot & Company Limited",
    "CENTEXT": "Century Extrusions Limited",
    "SHRITECH": "Shri Techtex Limited",
    "TOTAL": "Total Transport Systems Limited",
    "MITCON": "MITCON Consultancy & Engineering Services Limited",
    "ARHAM": "Arham Technologies Limited",
    "TOUCHWOOD": "Touchwood Entertainment Limited",
    "JOCIL": "Jocil Limited",
    "VAISHALI": "Vaishali Pharma Limited",
    "FCONSUMER": "Future Consumer Limited",
    "DIGIDRIVE": "Digidrive Distributors Limited",
    "KEL": "Kundan Edifice Limited",
    "CORALFINAC": "Coral India Finance & Housing Limited",
    "ACCURACY": "Accuracy Shipping Limited",
    "KALYANIFRG": "Kalyani Forge Limited",
    "NIRAJ": "Niraj Cement Structurals Limited",
    "SRIVASAVI": "Srivasavi Adhesive Tapes Limited",
    "IPSL": "Integrated Personnel Services Limited",
    "GTL": "GTL Limited",
    "RELCHEMQ": "Reliance Chemotex Industries Limited",
    "INDIANCARD": "Indian Card Clothing Company Limited",
    "MANGALAM": "Mangalam Drugs And Organics Limited",
    "PALREDTEC": "Palred Technologies Limited",
    "SIL": "Standard Industries Limited",
    "LEMERITE": "Le Merite Exports Limited",
    "BALPHARMA": "Bal Pharma Limited",
    "KOHINOOR": "Kohinoor Foods Limited",
    "AKANKSHA": "Akanksha Power and Infrastructure Limited",
    "RHFL": "Reliance Home Finance Limited",
    "LAMBODHARA": "Lambodhara Textiles Limited",
    "CUBEXTUB": "Cubex Tubings Limited",
    "GOLDKART": "Goldkart Jewels Limited",
    "AHLADA": "Ahlada Engineers Limited",
    "BEARDSELL": "Beardsell Limited",
    "IL&FSTRANS": "IL&FS Transportation Networks Limited",
    "HPIL": "Hindprakash Industries Limited",
    "PANSARI": "Pansari Developers Limited",
    "AMJLAND": "Amj Land Holdings Limited",
    "NOIDATOLL": "Noida Toll Bridge Company Limited",
    "DBSTOCKBRO": "DB (International) Stock Brokers Limited",
    "HOMESFY": "Homesfy Realty Limited",
    "S&SPOWER": "S&S Power Switchgears Limited",
    "GRCL": "Gayatri Rubbers And Chemicals Limited",
    "PULZ": "Pulz Electronics Limited",
    "AKI": "AKI India Limited",
    "AGRITECH": "Agri-Tech (India) Limited",
    "DCM": "DCM  Limited",
    "PRAKASHSTL": "Prakash Steelage Limited",
    "SOTAC": "Sotac Pharmaceuticals Limited",
    "SHRADHA": "Shradha Infraprojects Limited",
    "MADHUSUDAN": "Madhusudan Masala Limited",
    "JAINAM": "Jainam Ferro Alloys (I) Limited",
    "FONEBOX": "Fonebox Retail Limited",
    "SUNDARAM": "Sundaram Multi Pap Limited",
    "MRO-TEK": "MRO-TEK Realty Limited",
    "KREBSBIO": "Krebs Biochemicals and Industries Limited",
    "KHFM": "Khfm Hospitality And Facility Management Services Limited",
    "PASHUPATI": "Pashupati Cotspin Limited",
    "DUCOL": "Ducol Organics And Colours Limited",
    "AKSHOPTFBR": "Aksh Optifibre Limited",
    "WIPL": "The Western India Plywoods Limited",
    "PATINTLOG": "Patel Integrated Logistics Limited",
    "IBLFL": "IBL Finance Limited",
    "VR": "V R Infraspace Limited",
    "GICL": "Globe International Carriers Limited",
    "JHS": "JHS Svendgaard Laboratories Limited",
    "FLEXITUFF": "Flexituff Ventures International Limited",
    "AUSOMENT": "Ausom Enterprise Limited",
    "DANGEE": "Dangee Dums Limited",
    "DGCONTENT": "Digicontent Limited",
    "ARTNIRMAN": "Art Nirman Limited",
    "SHAH": "Shah Metacorp Limited",
    "SECURKLOUD": "SECUREKLOUD TECHNOLOGIES LIMITED",
    "SURYALAXMI": "Suryalakshmi Cotton Mills Limited",
    "BVCL": "Barak Valley Cements Limited",
    "GOYALALUM": "Goyal Aluminiums Limited",
    "TRANSTEEL": "Transteel Seating Technologies Limited",
    "BHANDARI": "Bhandari Hosiery Exports Limited",
    "PARTYCRUS": "Party Cruisers Limited",
    "MOTOGENFIN": "The Motor & General Finance Limited",
    "PALASHSECU": "Palash Securities Limited",
    "ANIKINDS": "Anik Industries Limited",
    "SHAHALLOYS": "Shah Alloys Limited",
    "TAINWALCHM": "Tainwala Chemical and Plastic (I) Limited",
    "SUVIDHAA": "Suvidhaa Infoserve Limited",
    "LOTUSEYE": "Lotus Eye Hospital and Institute Limited",
    "GANGESSECU": "Ganges Securities Limited",
    "AGNI": "Agni Green Power Limited",
    "SIKKO": "Sikko Industries Limited",
    "AMDIND": "AMD Industries Limited",
    "STEELCITY": "Steel City Securities Limited",
    "TPHQ": "Teamo Productions HQ Limited",
    "GLOBE": "Globe Textiles (India) Limited",
    "FRETAIL": "Future Retail Limited",
    "SOMATEX": "Soma Textiles & Industries Limited",
    "AAREYDRUGS": "Aarey Drugs & Pharmaceuticals Limited",
    "QUICKTOUCH": "Quicktouch Technologies Limited",
    "LASA": "Lasa Supergenerics Limited",
    "ZENITHDRUG": "Zenith Drugs Limited",
    "ZENITHSTL": "Zenith Steel Pipes & Industries Limited",
    "LPDC": "Landmark Property Development Company Limited",
    "NIRMAN": "Nirman Agri Genetics Limited",
    "NAGREEKEXP": "Nagreeka Exports Limited",
    "AVROIND": "AVRO INDIA LIMITED",
    "ALKALI": "Alkali Metals Limited",
    "AAATECH": "AAA Technologies Limited",
    "CINEVISTA": "Cinevista Limited",
    "ENERGYDEV": "Energy Development Company Limited",
    "ATALREAL": "Atal Realtech Limited",
    "GROBTEA": "The Grob Tea Company Limited",
    "SIMBHALS": "Simbhaoli Sugars Limited",
    "GLOBALPET": "Global Pet Industries Limited",
    "BANKA": "Banka BioLoo Limited",
    "PIONEEREMB": "Pioneer Embroideries Limited",
    "KEYFINSERV": "Keynote Financial Services Limited",
    "PKTEA": "The Peria Karamalai Tea & Produce Company Limited",
    "DELTAMAGNT": "Delta Manufacturing Limited",
    "ARCHIES": "Archies Limited",
    "MAHICKRA": "Mahickra Chemicals Limited",
    "OBCL": "Orissa Bengal Carrier Limited",
    "SAIFL": "Sameera Agro And Infra Limited",
    "INDSWFTLTD": "Ind-Swift Limited",
    "BIOFILCHEM": "Biofil Chemicals & Pharmaceuticals Limited",
    "VINNY": "Vinny Overseas Limited",
    "SAMBHAAV": "Sambhaav Media Limited",
    "DAMODARIND": "Damodar Industries Limited",
    "GANGAFORGE": "Ganga Forging Limited",
    "MHHL": "Mohini Health & Hygiene Limited",
    "VASWANI": "Vaswani Industries Limited",
    "HISARMETAL": "Hisar Metal Industries Limited",
    "PARASPETRO": "Paras Petrofils Limited",
    "SECMARK": "SecMark Consultancy Limited",
    "CELEBRITY": "Celebrity Fashions Limited",
    "PRESSTONIC": "Presstonic Engineering Limited",
    "SYNOPTICS": "Synoptics Technologies Limited",
    "YAARI": "Yaari Digital Integrated Services Limited",
    "AAKASH": "Aakash Exploration Services Limited",
    "REGENCERAM": "Regency Ceramics Limited",
    "TOKYOPLAST": "Tokyo Plast International Limited",
    "ZENITHEXPO": "Zenith Exports Limited",
    "MOKSH": "Moksh Ornaments Limited",
    "DOLLEX": "Dollex Agrotech Limited",
    "ARABIAN": "Arabian Petroleum Limited",
    "TREEHOUSE": "Tree House Education & Accessories Limited",
    "DEEM": "Deem Roll Tech Limited",
    "ARIHANTACA": "Arihant Academy Limited",
    "ASTRON": "Astron Paper & Board Mill Limited",
    "PANACHE": "Panache Digilife Limited",
    "PRITIKA": "Pritika Engineering Components Limited",
    "SAMPANN": "Sampann Utpadan India Limited",
    "ENFUSE": "Enfuse Solutions Limited",
    "MCL": "Madhav Copper Limited",
    "FIBERWEB": "Fiberweb (India) Limited",
    "PROLIFE": "Prolife Industries Limited",
    "PRECISION": "Precision Metaliks Limited",
    "BABAFP": "Baba Food Processing (India) Limited",
    "AUROIMPEX": "Auro Impex  & Chemicals Limited",
    "PRAMARA": "Pramara Promotions Limited",
    "PENTAGON": "Pentagon Rubber Limited",
    "MAL": "Mangalam Alloys Limited",
    "PIGL": "Power & Instrumentation (Gujarat) Limited",
    "RCDL": "Rajgor Castor Derivatives Limited",
    "MBECL": "Mcnally Bharat Engineering Company Limited",
    "SETCO": "Setco Automotive Limited",
    "HOLMARC": "Holmarc Opto-Mechatronics Limited",
    "TIMESGTY": "Times Guaranty Limited",
    "MAITREYA": "Maitreya Medicare Limited",
    "NIBL": "NRB Industrial Bearings Limited",
    "SIGNORIA": "Signoria Creation Limited",
    "KARMAENG": "Karma Energy Limited",
    "AVSL": "AVSL Industries Limited",
    "VELS": "Vels Film International Limited",
    "LATTEYS": "Latteys Industries Limited",
    "PNC": "Pritish Nandy Communications Limited",
    "MONOPHARMA": "Mono Pharmacare Limited",
    "VERTEXPLUS": "Vertexplus Technologies Limited",
    "MVKAGRO": "M.V.K. Agro Food Product Limited",
    "SVPGLOB": "SVP GLOBAL TEXTILES LIMITED",
    "DIL": "Debock Industries Limited",
    "BALKRISHNA": "Balkrishna Paper Mills Limited",
    "MARCO": "Marco Cables & Conductors Limited",
    "ORIENTLTD": "Orient Press Limited",
    "RILINFRA": "Rachana Infrastructure Limited",
    "SPYL": "Shekhawati Poly-Yarn Limited",
    "GTECJAINX": "G-TEC JAINX EDUCATION LIMITED",
    "YUDIZ": "Yudiz Solutions Limited",
    "AGROPHOS": "Agro Phos India Limited",
    "SHUBHLAXMI": "Shubhlaxmi Jewel Art Limited",
    "MCON": "Mcon Rasayan India Limited",
    "AMBANIORG": "Ambani Organics Limited",
    "AISL": "ANI Integrated Services Limited",
    "CPS": "C P S Shapers Limited",
    "HOVS": "HOV Services Limited",
    "KANANIIND": "Kanani Industries Limited",
    "SHIVAMILLS": "Shiva Mills Limited",
    "PRUDMOULI": "Prudential Sugar Corporation Limited",
    "SCML": "Sharp Chucks and Machines Limited",
    "AATMAJ": "Aatmaj Healthcare Limited",
    "KTL": "Kalahridhaan Trendz Limited",
    "CEREBRAINT": "Cerebra Integrated Technologies Limited",
    "EXCEL": "Excel Realty N Infra Limited",
    "SHEETAL": "Sheetal Universal Limited",
    "GRAPHISAD": "Graphisads Limited",
    "HECPROJECT": "HEC Infra Projects Limited",
    "SECURCRED": "SecUR Credentials Limited",
    "AROGRANITE": "Aro Granite Industries Limited",
    "MADHUCON": "Madhucon Projects Limited",
    "WEWIN": "WE WIN LIMITED",
    "KKVAPOW": "KKV Agro Powers Limited",
    "RELIABLE": "Reliable Data Services Limited",
    "AKSHAR": "Akshar Spintex Limited",
    "DHTL": "Docmode Health Technologies Limited",
    "PERFECT": "Perfect Infraengineers Limited",
    "MILTON": "Milton Industries Limited",
    "GOLDENTOBC": "Golden Tobacco Limited",
    "LEXUS": "Lexus Granito (India) Limited",
    "MAGSON": "Magson Retail And Distribution Limited",
    "SERVICE": "Service Care Limited",
    "ROML": "Raj Oil Mills Limited",
    "NGIL": "Nakoda Group of Industries Limited",
    "CLSL": "Crop Life Science Limited",
    "OMFURN": "Omfurn India Limited",
    "VIAZ": "Viaz Tyres Limited",
    "TRIDHYA": "Tridhya Tech Limited",
    "BANG": "Bang Overseas Limited",
    "MORARJEE": "Morarjee Textiles Limited",
    "MANUGRAPH": "Manugraph India Limited",
    "MALUPAPER": "Malu Paper Mills Limited",
    "UCL": "Ushanti Colour Chem Limited",
    "SIDDHIKA": "Siddhika Coatings Limited",
    "JAIPURKURT": "Nandani Creation Limited",
    "SPTL": "Sintex Plastics Technology Limited",
    "CELLPOINT": "Cell Point (India) Limited",
    "MICROPRO": "Micropro Software Solutions Limited",
    "COMMITTED": "Committed Cargo Care Limited",
    "HBSL": "HB Stockholdings Limited",
    "BANARBEADS": "Banaras Beads Limited",
    "SANGANI": "Sangani Hospitals Limited",
    "BDR": "BDR Buildcon Limited",
    "JETFREIGHT": "Jet Freight Logistics Limited",
    "MARSHALL": "Marshall Machines Limited",
    "ABMINTLLTD": "ABM International Limited",
    "TERASOFT": "Tera Software Limited",
    "AKG": "Akg Exim Limited",
    "PEARLPOLY": "Pearl Polymers Limited",
    "PODDARHOUS": "Poddar Housing and Development Limited",
    "SHANTHALA": "Shanthala FMCG Products Limited",
    "AARVEEDEN": "Aarvee Denims & Exports Limited",
    "REXPIPES": "Rex Pipes And Cables Industries Limited",
    "JETKNIT": "Jet Knitwears Limited",
    "ENSER": "Enser Communications Limited",
    "SHRENIK": "Shrenik Limited",
    "DNAMEDIA": "Diligent Media Corporation Limited",
    "MASTER": "Master Components Limited",
    "ACSAL": "Arvind and Company Shipping Agencies Limited",
    "SILGO": "Silgo Retail Limited",
    "AKASH": "Akash Infra-Projects Limited",
    "SEYAIND": "Seya Industries Limited",
    "AJOONI": "Ajooni Biotech Limited",
    "TFL": "Transwarranty Finance Limited",
    "TAPIFRUIT": "Tapi Fruit Processing Limited",
    "LFIC": "Lakshmi Finance & Industrial Corporation Limited",
    "WOMANCART": "Womancart Limited",
    "ASLIND": "ASL Industries Limited",
    "TIMESCAN": "Timescan Logistics (India) Limited",
    "ANKITMETAL": "Ankit Metal & Power Limited",
    "ICDSLTD": "ICDS Limited",
    "YCCL": "Yasons Chemex Care Limited",
    "UMA": "Uma Converter Limited",
    "WALPAR": "Walpar Nutritions Limited",
    "SONUINFRA": "Sonu Infratech Limited",
    "SEL": "Sungarner Energies Limited",
    "LIBAS": "Libas Consumer Products Limited",
    "3PLAND": "3P Land Holdings Limited",
    "BURNPUR": "Burnpur Cement Limited",
    "SITINET": "Siti Networks Limited",
    "RKDL": "Ravi Kumar Distilleries Limited",
    "MAKS": "Maks Energy Solutions India Limited",
    "SRPL": "Shree Ram Proteins Limited",
    "BMETRICS": "Bombay Metrics Supply Chain Limited",
    "ITALIANE": "Italian Edibles Limited",
    "LAXMICOT": "Laxmi Cotspin Limited",
    "UWCSL": "Ultra Wiring Connectivity System Limited",
    "TNTELE": "Tamilnadu Telecommunication Limited",
    "AMBICAAGAR": "Ambica Agarbathies & Aroma industries Limited",
    "ASHOKAMET": "Ashoka Metcast Limited",
    "ADL": "Archidply Decor Limited",
    "KHANDSE": "Khandwala Securities Limited",
    "VINEETLAB": "Vineet Laboratories Limited",
    "TECHIN": "Techindia Nirman Limited",
    "PATTECH": "Pattech Fitwell Tube Components Limited",
    "CBAZAAR": "Net Avenue Technologies Limited",
    "BINANIIND": "Binani Industries Limited",
    "21STCENMGM": "21st Century Management Services Limited",
    "KRIDHANINF": "Kridhan Infra Limited",
    "GODHA": "Godha Cabcon & Insulation Limited",
    "JFLLIFE": "Jfl Life Sciences Limited",
    "ISHAN": "Ishan International Limited",
    "NEXTMEDIA": "Next Mediaworks Limited",
    "SUULD": "Suumaya Industries Limited",
    "ARISTO": "Aristo Bio-Tech And Lifescience Limited",
    "TGBHOTELS": "TGB Banquets And Hotels Limited",
    "DKEGL": "D.K. Enterprises Global Limited",
    "MARINETRAN": "Marinetrans India Limited",
    "DESTINY": "Destiny Logistics & Infra Limited",
    "FLFL": "Future Lifestyle Fashions Limited",
    "ARSSINFRA": "ARSS Infrastructure Projects Limited",
    "ONELIFECAP": "Onelife Capital Advisors Limited",
    "INSPIRE": "Inspire Films Limited",
    "VSCL": "Vadivarhe Speciality Chemicals Limited",
    "SGL": "STL Global Limited",
    "EDUCOMP": "Educomp Solutions Limited",
    "SAGARDEEP": "Sagardeep Alloys Limited",
    "ROLLT": "Rollatainers Limited",
    "KONTOR": "Kontor Space Limited",
    "SHAIVAL": "Shaival Reality Limited",
    "AGARWALFT": "Agarwal Float Glass India Limited",
    "TECILCHEM": "TECIL Chemicals and Hydro Power Limited",
    "CMRSL": "Cyber Media Research & Services Limited",
    "NIDAN": "Nidan Laboratories and Healthcare Limited",
    "UNIINFO": "Uniinfo Telecom Services Limited",
    "COUNCODOS": "Country Condo's Limited",
    "AMEYA": "Ameya Precision Engineers Limited",
    "SUPERSPIN": "Super Spinning Mills Limited",
    "CYBERMEDIA": "Cyber Media (India) Limited",
    "TIJARIA": "Tijaria Polypipes Limited",
    "WILLAMAGOR": "Williamson Magor & Company Limited",
    "GSTL": "Globesecure Technologies Limited",
    "COMPINFO": "Compuage Infocom Limited",
    "MINDPOOL": "Mindpool Technologies Limited",
    "POLYSIL": "Polysil Irrigation Systems Limited",
    "HAVISHA": "Sri Havisha Hospitality and Infrastructure Limited",
    "JIWANRAM": "Jiwanram Sheoduttrai Industries Limited",
    "MADHAV": "Madhav Marbles and Granites Limited",
    "NKIND": "NK Industries Limited",
    "SANGINITA": "Sanginita Chemicals Limited",
    "MEGAFLEX": "Mega Flex Plastics Limited",
    "ADROITINFO": "Adroit Infotech Limited",
    "FMNL": "Future Market Networks Limited",
    "HEADSUP": "Heads UP Ventures Limited",
    "KEEPLEARN": "DSJ Keep Learning Limited",
    "ACEINTEG": "Ace Integrated Solutions Limited",
    "VIVIDHA": "Visagar Polytex Limited",
    "KCK": "Kck Industries Limited",
    "SABAR": "Sabar Flex India Limited",
    "AGUL": "A G Universal Limited",
    "FSC": "Future Supply Chain Solutions Limited",
    "GATECH": "GACM Technologies Limited",
    "IMPEXFERRO": "Impex Ferro Tech Limited",
    "QFIL": "Quality Foils (India) Limited",
    "KHAITANLTD": "Khaitan (India) Limited",
    "NARMADA": "Narmada Agrobase Limited",
    "FEL": "Future Enterprises Limited",
    "GRETEX": "Gretex Industries Limited",
    "VIJIFIN": "Viji Finance Limited",
    "BOHRAIND": "Bohra Industries Limited",
    "MOXSH": "Moxsh Overseas Educon Limited",
    "MOHITIND": "Mohit Industries Limited",
    "VILINBIO": "Vilin Bio Med Limited",
    "HRHNEXT": "HRH Next Services Limited",
    "SUNREST": "Sunrest Lifescience Limited",
    "SPRL": "Sp Refractories Limited",
    "SUMEETINDS": "Sumeet Industries Limited",
    "HYBRIDFIN": "Hybrid Financial Services Limited",
    "VERA": "Vera Synthetic Limited",
    "SAHAJ": "Sahaj Fashions Limited",
    "GOENKA": "Goenka Diamond and Jewels Limited",
    "QUADPRO": "Quadpro Ites Limited",
    "ANTGRAPHIC": "Antarctica Limited",
    "INDIFRA": "Indifra Limited",
    "ORIENTALTL": "Oriental Trimex Limited",
    "LCCINFOTEC": "LCC Infotech Limited",
    "LRRPL": "Lead Reclaim And Rubber Products Limited",
    "MASKINVEST": "Mask Investments Limited",
    "OLIL": "Oneclick Logistics India Limited",
    "KAVVERITEL": "Kavveri Telecom Products Limited",
    "MITTAL": "Mittal Life Style Limited",
    "GUJRAFFIA": "Gujarat Raffia Industries Limited",
    "KSHITIJPOL": "Kshitij Polyline Limited",
    "CONTI": "Continental Seeds and Chemicals Limited",
    "GLFL": "Gujarat Lease Financing Limited",
    "NTL": "Neueon Towers Limited",
    "CALSOFT": "California Software Company Limited",
    "AILIMITED": "Abhishek Integrations Limited",
    "KANDARP": "Kandarp Digi Smart BPO Limited",
    "PLADAINFO": "Plada Infotech Services Limited",
    "MTEDUCARE": "MT Educare Limited",
    "WINSOME": "Winsome Yarns Limited",
    "KAUSHALYA": "Kaushalya Infrastructure Development Corporation Limited",
    "DRL": "Dhanuka Realty Limited",
    "BRIGHT": "Bright Solar Limited",
    "NAGREEKCAP": "Nagreeka Capital & Infrastructure Limited",
    "TVVISION": "TV Vision Limited",
    "VCL": "Vaxtex Cotfab Limited",
    "TRANSWIND": "Transwind Infrastructures Limited",
    "DIGJAMLMTD": "Digjam Limited",
    "RITEZONE": "Rite Zone Chemcon India Limited",
    "METALFORGE": "Metalyst Forgings Limited",
    "SHANTI": "Shanti Overseas (India) Limited",
    "LYPSAGEMS": "Lypsa Gems & Jewellery Limited",
    "RICHA": "Richa Info Systems Limited",
    "MANAV": "Manav Infra Projects Limited",
    "NORBTEAEXP": "Norben Tea & Exports Limited",
    "TARAPUR": "Tarapur Transformers Limited",
    "BLUECHIP": "Blue Chip India Limited",
    "VIVO": "Vivo Collaboration Solutions Limited",
    "ORTINLAB": "Ortin Laboratories Limited",
    "SAROJA": "Saroja Pharma Industries India Limited",
    "JAKHARIA": "JAKHARIA FABRIC LIMITED",
    "MPTODAY": "Madhya Pradesh Today Media Limited",
    "SILLYMONKS": "Silly Monks Entertainment Limited",
    "EUROTEXIND": "Eurotex Industries and Exports Limited",
    "AMIABLE": "Amiable Logistics (India) Limited",
    "UMESLTD": "Usha Martin Education & Solutions Limited",
    "OMKARCHEM": "Omkar Speciality Chemicals Limited",
    "KALYANI": "Kalyani Commercials Limited",
    "ARENTERP": "Rajdarshan Industries Limited",
    "BKMINDST": "Bkm Industries Limited",
    "NIRAJISPAT": "Niraj Ispat Industries Limited",
    "INNOVATIVE": "Innovative Tyres and Tubes Limited",
    "ACCORD": "Accord Synergy Limited",
    "SHYAMTEL": "Shyam Telecom Limited",
    "SMVD": "SMVD Poly Pack Limited",
    "DCMFINSERV": "DCM Financial Services Limited",
    "PREMIER": "Premier Limited",
    "CREATIVEYE": "Creative Eye Limited",
    "AHIMSA": "Ahimsa Industries Limited",
    "ABNINT": "A B N Intercorp Limited",
    "ALPSINDUS": "Alps Industries Limited",
    "MELSTAR": "Melstar Information Technologies Limited",
    "JALAN": "Jalan Transolutions (India) Limited",
    "SABEVENTS": "Sab Events & Governance Now Media Limited",
    "SANCO": "Sanco Industries Limited",
    "BHALCHANDR": "Bhalchandram Clothing Limited",
    "LAKPRE": "Lakshmi Precision Screws Limited",
    "VASA": "Vasa Retail and Overseas Ltd",
    "CMMIPL": "CMM Infraprojects Limited"
}