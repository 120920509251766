import { createSlice } from "@reduxjs/toolkit";
import { symbolToNames } from "../constants/symbolToNames";

const INITIAL_STATE = {
    data: []
}

export const stockListSlice = createSlice({
    name: 'stockList',
    initialState: INITIAL_STATE,
    reducers: {
        update(state, action) {
            let stockArray = [];
            Object.entries(symbolToNames).forEach(([key, value]) => {
                stockArray.push({
                    label: value,
                    symbol: key
                })
            })
            state.data = stockArray;
        }
    }
})

export const stockListActions = stockListSlice.actions;