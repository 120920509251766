import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const ToggleButtonSelector = ({
    setSelectedValue,
    Color,
    TabsData,
    DefaultSelectedTab,
}) => {
    const [alignment, setAlignment] = React.useState(DefaultSelectedTab);
    setSelectedValue(alignment);
    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
        setSelectedValue(alignment);
    };
    return (
        <ToggleButtonGroup size="small" value={alignment} exclusive onChange={handleChange}>
            {TabsData.map((data) => (
                <ToggleButton
                    value={data.value}
                    className="toogleButton"
                    style={{
                        fontSize: "0.9rem",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                    }}
                >
                    {data.label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

export default ToggleButtonSelector;