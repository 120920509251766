import React from "react";
import { AuthContext } from "../../context/AuthContextProvider";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    const location = useLocation();
    const { currentUser } = React.useContext(AuthContext);

    return currentUser !== null ? (
        children
    ) : (
        <Navigate to="/login" replace state={{ from: location }} />
    );
}

export default PrivateRoute;