import { TableCell, TableRow, Typography } from '@mui/material';
import React from 'react'
import SubRow from './subRow';

const ParticipantRow = ({
    participant,
    ifl,
    ifs,
    icl,
    ics,
    ipl,
    ips,
    sfl,
    sfs,
    scl,
    scs,
    sps,
    spl,
    cashData
}) => {
    return (
        <>
            <TableRow sx={{ borderColor: 'black', '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row"
                    rowSpan={(participant === 'FII' || participant === 'DII') ? 5 : 4}
                    align='center'>
                    <Typography fontWeight={900} fontSize='2.5rem'>
                        {participant}
                    </Typography>
                </TableCell>
                <SubRow segment="Index Futures" netChange={ifl - ifs} />
            </TableRow>
            <TableRow>
                <SubRow segment="Index Options" netChange={icl - ics - ipl + ips} />
            </TableRow>
            <TableRow>
                <SubRow segment="Stock Futures" netChange={sfl - sfs} />
            </TableRow>
            <TableRow>
                <SubRow segment="Stock Options" netChange={scl - scs - spl + sps} />
            </TableRow>
            {
                participant === 'FII' &&
                <TableRow>
                    <SubRow segment="Cash" netChange={Number(cashData.fiiBuy) - Number(cashData.fiiSell)} />
                </TableRow>
            }
            {
                participant === 'DII' &&
                <TableRow>
                    <SubRow segment="Cash" netChange={Number(cashData.diiBuy) - Number(cashData.diiSell)} />
                </TableRow>
            }
        </>
    );
};

export default ParticipantRow