import React, { useEffect, useState } from 'react'
import TopStats from './topStats'
import { Autocomplete, Breadcrumbs, Chip, Link, Typography } from '@mui/joy'
import { Box, Stack, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import SingleStockHistory from './singleStockHistory'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { APP_BAR_HEIGHT } from '../navigation/constants'
import { DELIVERY_SEARCH_HEIGHT } from './constants'

const StockDelivery = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const stockList = useSelector(state => state.stockList.data);
  const [selectedStock, setSelectedStock] = useState(searchParams.get("symbol") || "");
  const topDeliverySyncDate = useSelector(state => state.topDeliveryData.data);

  const handleSelectStock = (symbol) => {
    if (!symbol) {
      navigate(`/stockDelivery`);
      return;
    }
    setSearchParams({symbol});
    navigate(`/stockDelivery?symbol=${symbol}`);
  }

  useEffect(() => {
    setSelectedStock(searchParams.get("symbol"));
  }, [searchParams])

  return (
    <Stack direction='column' padding={0}>
      <Stack direction='row' padding={1} boxSizing='border-box' height={DELIVERY_SEARCH_HEIGHT}>
        <Box flexGrow={1}>
          <Breadcrumbs>
            <Link color="primary" href="/stockDelivery">
              Stock delivery
            </Link>
            <Typography>{!selectedStock ? 'Top deliveries' : selectedStock}</Typography>
          </Breadcrumbs>
        </Box>
        <Autocomplete
          placeholder='Select stock'
          options={stockList}
          onChange={(event, value) => {
            if (value === null) {
              handleSelectStock('')
            } else {
              handleSelectStock(value.symbol)
            }
          }}
          renderInput={(params) => <TextField {...params} label="Stock" />}
        />
      </Stack>
      <Box flexGrow={1} key={selectedStock}>
        {
          selectedStock === '' || selectedStock === null ?
            <TopStats /> :
            <SingleStockHistory stock={selectedStock} />
        }
      </Box>
    </Stack>
  )
}

export default StockDelivery