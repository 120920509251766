import { Button, CircularProgress } from '@mui/joy';
import { Box, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import ToggleButtonSelector from './ToggleButtonSelector';

const FutureNetOIvsIndex = () => {
    const [timeframe, setTireframe] = useState('1m');
    const [selectedIndex, setSelectedIndex] = React.useState("Nifty 50");
    const [selectedParticipant, setSelectedParticipant] = React.useState("fii");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const getFIIDIIData = async () => {
        setLoading(true);
        setError(false);
        try {
            let res = await axios.get(process.env.REACT_APP_SERVICE_BASE_URL + "/fiidii/OIvsIndex/" + timeframe);
            res = res.data.data.sort((a, b) => new Date(a.date) - new Date(b.date));
            let flattened = [];
            res.forEach(element => {
                flattened.push({
                    date: element.date,
                    ...element.closingIndexOI,
                    ...element.closingIndexPrice
                })
            });
            setData(flattened);
        } catch (err) {
            setError(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        getFIIDIIData();
    }, [timeframe]);

    if (loading) {
        return (
            <Stack alignItems="center" justifyContent="center" height="100%">
                <CircularProgress />
            </Stack>
        );
    }

    if (error) {
        return (
            <Stack direction='column' alignItems="center" justifyContent="center" height="100%">
                <Typography variant='h6'>Something went wrong</Typography>
                <Button>Try again</Button>
            </Stack>
        );
    }

    return (
        <>
            <Stack direction="row" justifyContent="center" gap={3} margin={2}>
                <ToggleButtonSelector
                    setSelectedValue={setTireframe}
                    Color="secondary"
                    DefaultSelectedTab={timeframe}
                    TabsData={[
                        { value: "7d", label: "7D" },
                        { value: "1m", label: "1M" },
                        { value: "3m", label: "3M" },
                        { value: "6m", label: "6M" },
                        { value: "1y", label: "1Y" },
                    ]}
                />

                <ToggleButtonSelector
                    setSelectedValue={setSelectedParticipant}
                    Color="primary"
                    DefaultSelectedTab={selectedParticipant}
                    TabsData={[
                        { value: "fii", label: "FII" },
                        { value: "dii", label: "DII" },
                        { value: "pro", label: "PRO" },
                        { value: "retail", label: "Retail" },
                    ]}
                />
                <ToggleButtonSelector
                    setSelectedValue={setSelectedIndex}
                    Color="secondary"
                    DefaultSelectedTab={selectedIndex}
                    TabsData={[
                        { value: "Nifty 50", label: "Nifty 50" },
                        { value: "Nifty Bank", label: "Nifty Bank" },
                    ]}
                />
            </Stack>
            <ResponsiveContainer width={"97%"} height={'90%'} style={{ margin: 5 }}>
                <LineChart
                    data={data}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis yAxisId="left" stroke="blue" strokeWidth="2"
                        domain={['auto']}
                    >
                        <Label angle={-90} position={"insideLeft"}
                            style={{ fontSize: '80%', fontWeight: '900', fontSize: '1rem' }}>
                            {selectedParticipant.toUpperCase() + ' Index Future OI'}
                        </Label>
                    </YAxis>
                    <YAxis
                        yAxisId="right"
                        orientation="right"
                        stroke="red"
                        strokeWidth="2"
                        domain={['auto']}
                    >
                        <Label angle={90} position={"insideRight"}
                            style={{ fontSize: '80%', fontWeight: '900', fontSize: '1rem' }}>
                            {selectedIndex}
                        </Label>
                    </YAxis>
                    <Tooltip />
                    <Legend verticalAlign='top' />
                    <Line
                        yAxisId="left"
                        type="monotone"
                        dataKey={selectedParticipant}
                        stroke="blue"
                        strokeWidth="2"
                        dot={false}
                    />
                    <Line
                        yAxisId="right"
                        type="monotone"
                        dataKey={selectedIndex}
                        stroke="red"
                        strokeWidth="2"
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </>
    )
}

export default FutureNetOIvsIndex