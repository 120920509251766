import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Stack, Typography } from '@mui/material';
import { Button, Chip, CircularProgress } from '@mui/joy';
import { TrendingDown, TrendingUp } from '@mui/icons-material';
import axios from 'axios';
import SubRow from './subRow';
import ParticipantRow from './ParticipantRow';
import { useOutletContext } from 'react-router-dom';
import { getDateString, yymmddToDashedDate } from '../../utils/DateUtil';

const SingleDayData = () => {
    const [singleDayData, setData] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const [errorWhileLoading, setErrorWhileLoading] = React.useState(false);
    const handleTabName = useOutletContext();

    const getFIIDIIData = async () => {
        setLoading(true);
        setErrorWhileLoading(false);
        try {
            const res = await axios.get(process.env.REACT_APP_SERVICE_BASE_URL + "/fiidii/lastSingleDayData");
            setData(res.data.data);
            handleTabName("single_day_data_tab", getDateString(yymmddToDashedDate(res.data.data.date)));
        } catch (err) {
            setErrorWhileLoading(true);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        getFIIDIIData();
    }, [])

    return (
        <Stack alignItems="center" justifyContent="center" height="100%">
            {loading ?
                <CircularProgress /> :
                errorWhileLoading ?
                    <Stack direction='column' alignItems='center'>
                        <Typography>
                            Something went wrong
                        </Typography>
                        <Button onClick={getFIIDIIData}>
                            Try again
                        </Button>
                    </Stack> :
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography fontWeight={650} fontSize='1.2rem'>
                                            Participant
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography fontWeight={650} fontSize='1.2rem'>
                                            Segment
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography fontWeight={650} fontSize='1.2rem'>
                                            Net Change
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography fontWeight={650} fontSize='1.2rem'>
                                            Interpetation
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <ParticipantRow
                                    participant="FII"
                                    ifl={singleDayData.derivativesOIChange.FIFL}
                                    ifs={singleDayData.derivativesOIChange.FIFS}
                                    icl={singleDayData.derivativesOIChange.FICL}
                                    ics={singleDayData.derivativesOIChange.FICS}
                                    ipl={singleDayData.derivativesOIChange.FIPL}
                                    ips={singleDayData.derivativesOIChange.FIPS}
                                    sfl={singleDayData.derivativesOIChange.FSFL}
                                    sfs={singleDayData.derivativesOIChange.FSFS}
                                    scl={singleDayData.derivativesOIChange.FSCL}
                                    scs={singleDayData.derivativesOIChange.FSCS}
                                    spl={singleDayData.derivativesOIChange.FSPL}
                                    sps={singleDayData.derivativesOIChange.FSPS}
                                    cashData={singleDayData.cash}
                                />
                                <ParticipantRow
                                    participant="DII"
                                    ifl={singleDayData.derivativesOIChange.DIFL}
                                    ifs={singleDayData.derivativesOIChange.DIFS}
                                    icl={singleDayData.derivativesOIChange.DICL}
                                    ics={singleDayData.derivativesOIChange.DICS}
                                    ipl={singleDayData.derivativesOIChange.DIPL}
                                    ips={singleDayData.derivativesOIChange.DIPS}
                                    sfl={singleDayData.derivativesOIChange.DSFL}
                                    sfs={singleDayData.derivativesOIChange.DSFS}
                                    scl={singleDayData.derivativesOIChange.DSCL}
                                    scs={singleDayData.derivativesOIChange.DSCS}
                                    spl={singleDayData.derivativesOIChange.DSPL}
                                    sps={singleDayData.derivativesOIChange.DSPS}
                                    cashData={singleDayData.cash}
                                />
                                <ParticipantRow
                                    participant="PRO"
                                    ifl={singleDayData.derivativesOIChange.PIFL}
                                    ifs={singleDayData.derivativesOIChange.PIFS}
                                    icl={singleDayData.derivativesOIChange.PICL}
                                    ics={singleDayData.derivativesOIChange.PICS}
                                    ipl={singleDayData.derivativesOIChange.PIPL}
                                    ips={singleDayData.derivativesOIChange.PIPS}
                                    sfl={singleDayData.derivativesOIChange.PSFL}
                                    sfs={singleDayData.derivativesOIChange.PSFS}
                                    scl={singleDayData.derivativesOIChange.PSCL}
                                    scs={singleDayData.derivativesOIChange.PSCS}
                                    spl={singleDayData.derivativesOIChange.PSPL}
                                    sps={singleDayData.derivativesOIChange.PSPS}
                                />
                                <ParticipantRow
                                    participant="Retail"
                                    ifl={singleDayData.derivativesOIChange.RIFL}
                                    ifs={singleDayData.derivativesOIChange.RIFS}
                                    icl={singleDayData.derivativesOIChange.RICL}
                                    ics={singleDayData.derivativesOIChange.RICS}
                                    ipl={singleDayData.derivativesOIChange.RIPL}
                                    ips={singleDayData.derivativesOIChange.RIPS}
                                    sfl={singleDayData.derivativesOIChange.RSFL}
                                    sfs={singleDayData.derivativesOIChange.RSFS}
                                    scl={singleDayData.derivativesOIChange.RSCL}
                                    scs={singleDayData.derivativesOIChange.RSCS}
                                    spl={singleDayData.derivativesOIChange.RSPL}
                                    sps={singleDayData.derivativesOIChange.RSPS}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </Stack>
    )
}

export default SingleDayData