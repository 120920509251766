import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import LoginOptions from "./LoginOptions";
import { AuthContext } from "../../context/AuthContextProvider";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { Box } from "@mui/material";

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentUser } = React.useContext(AuthContext);

    if (currentUser) {
        if (location.state?.from) {
            navigate(location.state.from);
        } else {
            return <Navigate to='/' />
        }
    }

    return (
        <Stack direction='row' height='100vh'>
            <Box bgcolor='#1B1A55' flexGrow={1} alignContent='center'>
                <Typography color='white' variant="h1" align="center" marginBottom={2}>MarketDirection.in</Typography>
                <Typography color='white' align="center" variant="h6">Stay ahead of the market with unmached insights</Typography>
            </Box>
            <Box height='100%' width='25vw' alignContent='center' marginX='10vw'>
                <Typography marginBottom={1} variant="h5" align="center">You're one click away from some awesome insights</Typography>
                <LoginOptions />
                <Typography marginTop={1} variant="caption" align="center">By signing up, I agree to MarketDirection's Terms & conditions.</Typography>
            </Box>
        </Stack>
    );
}

export default Login;