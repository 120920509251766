import { AccessTime } from '@mui/icons-material'
import { Chip } from '@mui/joy';
import React from 'react'
import { useSelector } from 'react-redux';

const MarketStatus = () => {
    const marketStatus = useSelector(state => state.marketStatus.data);
    return (
        <>
            <Chip size='md' variant='solid' color='neutral' sx={{ mr: 1 }} startDecorator={<AccessTime />}>
                {"Last updated: " + marketStatus.dateTime}
            </Chip>
            <Chip size='md' variant='solid' color='danger'>
                {marketStatus.status === "Closed" ? "Market closed" : "Market open"}
            </Chip>
        </>
    )
}

export default MarketStatus