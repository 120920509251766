import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    data: {}
}

export const marketStatusSlice = createSlice({
    name: 'marketStatus',
    initialState: INITIAL_STATE,
    reducers: {
        update(state, action) {
            state.data = action.payload
        }
    }
})

export const marketStatusActions = marketStatusSlice.actions;