import React from 'react'
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { Business, CalendarMonth, ImportExport, LocalShipping } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { Badge, Typography } from '@mui/material';
import { APP_BAR_HEIGHT } from './constants';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    height: APP_BAR_HEIGHT,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const SideDrawer = ({ open, handleDrawerClose }) => {
    const currentLocation = useLocation();

    const backgroundColor = (location) => {
        return currentLocation.pathname.includes(location) ? '#B6BBC4' : 'white';
    }

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            background: backgroundColor('strengthIndicator')
                        }}
                        component={Link} to='/strengthIndicator'
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 1 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <Badge
                                variant='dot'
                                sx={{
                                    "& .MuiBadge-badge": {
                                        backgroundColor: "maroon",
                                    }
                                }}
                            >
                                <ImportExport />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary="Live OI Strength" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            background: backgroundColor('pcr')
                        }}
                        component={Link} to='/pcr'
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 1 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <Badge
                                variant='dot'
                                sx={{
                                    "& .MuiBadge-badge": {
                                        backgroundColor: "maroon",
                                    }
                                }}
                            >
                                <Typography fontSize={12} fontWeight='bolder'>
                                    PCR
                                </Typography>
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary="Put Call Ratio" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            background: backgroundColor('fiidii')
                        }}
                        component={Link} to='/fiidii/singleDayData'
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 1 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <Business />
                        </ListItemIcon>
                        <ListItemText primary="FII DII Data" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            background: backgroundColor('resultsCalendar')
                        }}
                        component={Link} to='/resultsCalendar'
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 1 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <CalendarMonth />
                        </ListItemIcon>
                        <ListItemText primary="Results calendar" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            background: backgroundColor('stockDelivery')
                        }}
                        component={Link} to='/stockDelivery'
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 1 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <LocalShipping />
                        </ListItemIcon>
                        <ListItemText primary="Stock delivery" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
        </Drawer>
    )
}

export default SideDrawer